import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { invoice_circle1 } from "../../imagepath";

const DonutChart = ({ chartData }) => {
  useEffect(() => {
    if (document.querySelector("#donut-chart-dash")) {
      const labels = Object.keys(chartData);
      const data = Object.values(chartData);

      const donutChartOptions = {
        chart: {
          height: 270,
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        series: data,
        labels: labels,
        dataLabels: {
          enabled: false,
          formatter: (val) => `${val.toFixed(1)}%`, // Show percentage with one decimal
          style: {
            fontSize: "8px", 
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "bottom",
        },
      };

      const donut = new ApexCharts(
        document.querySelector("#donut-chart-dash"),
        donutChartOptions
      );

      donut.render();

      return () => donut.destroy();
    }
  }, [chartData]); // Re-render when chartData changes

  return <div id="donut-chart-dash"></div>;
};

export default DonutChart;
