import React, { useState, useEffect } from "react";
import { invoice_icon2 } from "../../imagepath";

const DoctorRefPatients = ({ dashboardData }) => {
    const [tableData, setTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    useEffect(() => {
        // Extract and format data for Doctor Referred Patients
        if (dashboardData && dashboardData.doctorRef_patients) {
            const formattedData = dashboardData.doctorRef_patients.map(([doctorName, clinicName, patientCount]) => ({
                doctorName,
                clinicName,
                patientCount,
            }));
            setTableData(formattedData);
        }
    }, [dashboardData]);

    const sortData = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }

        const sortedArray = [...tableData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });

        setTableData(sortedArray);
        setSortConfig({ key, direction });
    };

    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "↑" : "↓";
        }
        return null;
    };

    return (
        <div className="col-12 col-md-12 col-xl-6">
            <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Doctor Referred Patients</h4>
                    <span className="invoices-widget-icon">
                        <img src={invoice_icon2} alt="#" style={{ width: '40px', height: '40px' }} />
                    </span>
                </div>
                <div className="card-body p-0 table-dash">
                    <div
                        className="table-responsive"
                        style={{
                            minHeight: "200px",
                            maxHeight: "200px",
                            overflowY: "auto",
                        }}
                    >
                        <table className="table mb-0 border-0 custom-table">
                            <thead className="bg-light">
                                <tr>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Clinic Name{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Doctor Name{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Total Patients{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.length > 0 ? (
                                    tableData.map((row, index) => (
                                        <tr key={index}>
                                            <td className="table-image appoint-doctor">
                                                <p className="inovices-all">{row.clinicName}</p>
                                            </td>
                                            <td style={{ fontWeight: "500", color: "#1b2559" }}>{row.doctorName}</td>
                                            <td style={{ color: "#2e37a4", fontWeight: "500" }}>{row.patientCount}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorRefPatients;
