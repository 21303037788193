import React, { useState, useEffect } from "react";
import { invoice_icon1 } from "../../imagepath";


const DoctorRefReports = ({ dashboardData }) => {
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    useEffect(() => {
        // Process API response to extract doctorRef_reports data for the table
        if (dashboardData && dashboardData.doctorRef_reports) {
            const formattedData = dashboardData.doctorRef_reports.map(([doctorName, clinicName, reportCount]) => ({
                doctorName,
                clinicName,
                reportCount,
            }));
            setSortedData(formattedData);
        }
    }, [dashboardData]);

    // Sort function
    const sortData = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }

        const sortedArray = [...sortedData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });

        setSortedData(sortedArray);
        setSortConfig({ key, direction });
    };

    // Get sorting arrow based on direction
    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "↑" : "↓";
        }
        return null;
    };

    return (
        <div className="col-12 col-md-12 col-xl-6">
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Doctor Referred Reports</h4>
                    <span className="invoices-widget-icon">
                        <img src={invoice_icon1} alt="#" style={{ width: '40px', height: '40px' }} />
                    </span>
                </div>

                <div className="card-body p-0 table-dash">
                    <div
                        className="table-responsive"
                        style={{
                            minHeight: "200px",
                            maxHeight: "200px",
                            overflowY: "auto",
                        }}
                    >
                        <table className="table mb-0 border-0 custom-table">
                            {/* Table Header */}
                            <thead className="bg-light">
                                <tr>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Clinic Name{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Doctor Name{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Total Reports{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                                {sortedData.length > 0 ? (
                                    sortedData.map((data, index) => (
                                        <tr key={index}>
                                            <td className="table-image appoint-doctor">
                                                <p className="inovices-all">{data.clinicName}</p>
                                            </td>
                                            <td style={{ fontWeight: "500", color: "#1b2559" }}>{data.doctorName}</td>
                                            <td style={{ color: "#2e37a4", fontWeight: "500" }}>{data.reportCount}</td>
                                        </tr>


                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DoctorRefReports;
