import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import Chart from 'chart.js/auto';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const ECGGraph = ({ patient }) => {
    const ecgChartRef = useRef(null);
    const [chart, setChart] = useState(null);
    const [chartData, setChartData] = useState({ labels: [], data: [] });
    const [buffer, setBuffer] = useState(null); // Store only the first ECG data object
    const [plotGraph, setPlotGraph] = useState(false); // Track when to start plotting
    const [currentIndex, setCurrentIndex] = useState(0);
    const [windowSize, setWindowSize] = useState(300);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    let plottedIndex = 0;

    const options = {
        animation: {
            duration: 0, // Disable animation for smoother experience
        },
        scales: {
            x: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
            y: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const createChart = () => {
        const canvas = ecgChartRef.current;
        const container = canvas.parentNode;

        // Set explicit width and height values
        const desiredWidth = 350;
        const desiredHeight = 60;

        canvas.width = desiredWidth * 1.5; // Account for device pixel ratio (e.g., 1.5x scaling)
        canvas.height = desiredHeight * 1.5; // Account for device pixel ratio

        // Apply inline styles for rendering
        canvas.style.display = 'block';
        canvas.style.boxSizing = 'border-box';
        canvas.style.width = `${desiredWidth}px`; // Render width
        canvas.style.height = `${desiredHeight}px`; // Render height

        const ctx = canvas.getContext('2d');
        const ecgChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'ECG Chart',
                    data: [],
                    borderColor: 'rgba(36, 85, 174, 1)',
                    borderWidth: 1.5,
                    fill: false,
                    pointRadius: 0,
                }],
            },
            options: options,
        });

        setChart(ecgChart);
    };


    const updateChart = (newData) => {
        if (newData) {
            chartData.labels.push(newData.t);
            chartData.data.push(newData.e);

            if (chartData.data.length > windowSize) {
                chartData.labels.splice(0, 1);
                chartData.data.splice(0, 1);
            }

            setChartData({ ...chartData });

            if (chart) {
                chart.data.labels = chartData.labels;
                chart.data.datasets[0].data = chartData.data;
                chart.update();
            }

            plottedIndex++;
        }
    };

    const animateChart = () => {
        if (patient?.vitals?.ecg_clean && patient.vitals.ecg_clean[plottedIndex]) {
            const newData = patient.vitals.ecg_clean[plottedIndex];
            updateChart(newData);
        }
    };

    useEffect(() => {
        if (patient?.vitals?.ecg_clean && patient.vitals.ecg_clean.length > 0) {
            console.log("Received ECG data, length:", patient.vitals.ecg_clean.length);

            if (!initialDataLoaded) {
                // Store the first ECG data object
                setBuffer(patient.vitals.ecg_clean);
                setInitialDataLoaded(true);
            } else if (initialDataLoaded && !plotGraph) {
                // Start plotting after the second ECG object is received
                setPlotGraph(true);
            }
        }
    }, [patient?.vitals?.ecg_clean]);

    useEffect(() => {
        if (plotGraph) {
            // Use setInterval for animation
            const interval = setInterval(() => {
                animateChart();
            }, 10);

            return () => clearInterval(interval);
        }
    }, [plotGraph]);

    useEffect(() => {
        createChart();

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, []);

    return (
        <div
            style={{
                background: '#f9fafb',
                padding: '10px',
                borderRadius: '8px',
                textAlign: 'center',
                height: '80px',
                width: '100%',
                display: 'flex', // Ensures alignment
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <canvas id={"ecg-chart" + patient?.patient_id} ref={ecgChartRef}></canvas>
        </div>

    );
};

export default ECGGraph;