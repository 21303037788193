import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define color ranges for vital parameters
const vitalRanges = {
    bp: {
        SBP: [
            { range: [0, 60], color: '#00d3c7' },
            { range: [80, 100], color: '#fef5e4' },
            { range: [120, 140], color: '#FFF2E5' },
            { range: [140, 160], color: '#ffe5e6' },
            { range: [160, Infinity], color: '#ffe5e6' },
        ],
        DBP: [
            { range: [0, 40], color: '#ffe5e6' },
            { range: [40, 60], color: '#fef5e4' },
            { range: [60, 80], color: '#FFF2E5' },
            { range: [90, 110], color: '#ffe5e6' },
            { range: [110, 130], color: '#ffe5e6' },
            { range: [130, Infinity], color: '#ffe5e6' },
        ],
        hr: [
            { range: [0, 19], color: '#ffe5e6' },
            { range: [20, 39], color: '#FFF2E5' },
            { range: [40, 59], color: '#fef5e4' },
            { range: [60, 100], color: '#f9fafb' }, // Normal range
            { range: [101, 110], color: '#fef5e4' },
            { range: [111, 130], color: '#FFF2E5' },
            { range: [131, Infinity], color: '#ffe5e6' },
        ],
        rr: [
            { range: [0, 3], color: '#ffe5e6' },
            { range: [4, 7], color: '#FFF2E5' },
            { range: [8, 11], color: '#fef5e4' },
            { range: [12, 20], color: '#f9fafb' }, // Normal range
            { range: [21, 25], color: '#fef5e4' },
            { range: [26, 30], color: '#FFF2E5' },
            { range: [31, Infinity], color: '#ffe5e6' },
        ],
        spo2: [
            { range: [0, 85], color: '#ffe5e6' },
            { range: [85, 90], color: '#FFF2E5' },
            { range: [90, 94], color: '#fef5e4' },
            { range: [95, 100], color: '#f9fafb' }, // Normal range
        ],
        skt: [
            { range: [0, 94.1], color: '#ffe5e6' },
            { range: [94.1, 95.9], color: '#fef5e4' },
            { range: [96, 99.4], color: '#f9fafb' }, // Normal range
            { range: [99.5, Infinity], color: '#ffe5e6' },
        ],
    }
};

const ProcessingDots = ({ dotStyle }) => (
    <div style={{ display: 'inline-flex' }}>
        <div className="dot" style={dotStyle(0)}></div>
        <div className="dot" style={dotStyle(1)}></div>
        <div className="dot" style={dotStyle(2)}></div>
        <style>
            {`
                @keyframes blink {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }
                .dot {
                    width: 6px;
                    height: 6px;
                    margin: 0 2px;
                    background-color: #000;
                    border-radius: 50%;
                    animation: blink 1.4s infinite;
                }
            `}
        </style>
    </div>
);

const VitalCard = ({ label, value, type, patientId, muteTimers, customRanges }) => {
    const [alertedVitals, setAlertedVitals] = useState({});
    const audioRef = useRef(new Audio('/sounds/notification-alert.mp3'));

    const vitalLabels = {
        SBP: "Systolic BP",
        DBP: "Diastolic BP",
        hr: "Heart Rate",
        rr: "Respiratory Rate",
        spo2: "Oxygen Saturation",
        skt: "Skin Temperature",
    };

    const displayLabelText = vitalLabels[type] || type;
    const displayValue = value !== 'N/A' ? value : <ProcessingDots dotStyle={dotStyle} />;

    const isVitalMuted = () => {
        const muteData = muteTimers[patientId];
        return muteData?.isMuted && muteData?.muteUntil > Date.now();
    };

    const playAlertSound = () => {
        if (!isVitalMuted()) {
            audioRef.current.play().catch(error => {
                console.error('Error playing alert sound:', error);
            });
        }
    };

    const getBackgroundColor = (type, value) => {
        if (value === 'N/A') return '#f9fafb';

        const numericValue = Number(value);
        
        // Check custom ranges first
        const customRange = customRanges[type.toLowerCase()];
        if (customRange?.min && customRange?.max) {
            const min = Number(customRange.min);
            const max = Number(customRange.max);
            return (numericValue >= min && numericValue <= max) ? '#f9fafb' : '#ffe5e6';
        }

        // Handle default ranges
        const ranges = type === 'SBP' || type === 'DBP' 
            ? vitalRanges.bp[type]
            : vitalRanges.bp[type.toLowerCase()];

        if (!ranges) return '#f9fafb';

        // Find matching range
        for (const { range, color } of ranges) {
            if (numericValue >= range[0] && numericValue < range[1]) {
                return color;
            }
        }
        
        return '#ffe5e6'; // Default alert color if outside all ranges
    };

    useEffect(() => {
        if (value === 'N/A') return;

        const numericValue = Number(value);
        const vitalType = type.toLowerCase();
        const customRange = customRanges[vitalType];
        const currentColor = getBackgroundColor(type, value);

        const shouldAlert = () => {
            if (customRange?.min && customRange?.max) {
                return numericValue < Number(customRange.min) || numericValue > Number(customRange.max);
            }
            return currentColor === '#ffe5e6';
        };

        if (shouldAlert() && !isVitalMuted()) {
            setAlertedVitals(prev => {
                if (!prev[vitalType]) {
                    const rangeText = customRange?.min && customRange?.max
                        ? `(Custom range: ${customRange.min}-${customRange.max})`
                        : '(Default range)';

                    const alertMessage = `Vital Alert: ${displayLabelText} is abnormal ${rangeText} for Patient ID: ${patientId}`;
                    
                    playAlertSound();
                    toast.error(alertMessage, {
                        position: 'top-center',
                        autoClose: 2000,
                    });

                    return { ...prev, [vitalType]: true };
                }
                return prev;
            });
        } else {
            setAlertedVitals(prev => {
                if (prev[vitalType]) {
                    return { ...prev, [vitalType]: false };
                }
                return prev;
            });
        }
    }, [value, type, patientId, customRanges, muteTimers, displayLabelText]);

    const backgroundColor = getBackgroundColor(type, value);

    return (
        <div
            style={{
                background: backgroundColor,
                padding: '10px',
                borderRadius: '8px',
                textAlign: 'center',
            }}
        >
            <div style={{ fontSize: '14px', display: 'flex', gap: '8px', alignItems: 'center' }}>
                {label}
                {displayValue}
            </div>
        </div>
    );
};

const dotStyle = (delayIndex) => ({
    animationDelay: `${0.2 * delayIndex}s`,
});

export default VitalCard;