import React from 'react';
import vectorIcon from '../../../assets/img/Vector.svg';
import lungsIcon from '../../../assets/img/lungs.png';
import bpIcon from "../../../assets/img/Blood_Pressure.svg";
import Cross from "../../../assets/img/x.svg";
import VitalCard from './colorRanges';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import patientApi from '../../../services/ClientServices/PatientRoutes';
import { imagesend } from '../../imagepath';
import ECGGraph from './EcgGraph';
import MuteButton from "../../../assets/img/mute.png"
import UnmuteButton from "../../../assets/img/unmute.png"
import CustomizeModal from './CustomizeModal';

const CardsGrid = ({ patientCards, selectPatient, patientId }) => {
  const [showModal, setShowModal] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false); // For unassign popup
  const [selectedPatientForUnassign, setSelectedPatientForUnassign] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [customRanges, setCustomRanges] = useState({});
  const [initialDurations, setInitialDurations] = useState({});
  const [timers, setTimers] = useState({});
  const [muteTimers, setMuteTimers] = useState({});

  //count down timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimers((prevTimers) => {
        const updatedTimers = {};
        Object.keys(prevTimers).forEach((id) => {
          if (prevTimers[id] > 0) {
            updatedTimers[id] = prevTimers[id] - 1; // Decrement the timer
          } else {
            updatedTimers[id] = 0; // Ensure it doesn't go below 0
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    // Set initial durations only when patientCards change
    const initialTimers = {};
    patientCards.forEach((patientCard) => {
      if (!initialDurations[patientCard.patient_id]) {
        initialTimers[patientCard.patient_id] = patientCard.duration;
      }
    });
    setInitialDurations((prev) => ({ ...prev, ...initialTimers }));
    setTimers((prev) => ({ ...prev, ...initialTimers }));
  }, [patientCards]);

  const formatDuration = (duration) => {
    if (!duration) return "00:00";
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    const formattedHours = hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
  };
  //count down timer - end

  //mute button 
  const handleMuteClick = (patientId) => {
    const isMuted = muteTimers[patientId]?.isMuted;
    if (isMuted) {
      // Unmute the patient
      setMuteTimers((prev) => {
        const updated = { ...prev };
        delete updated[patientId];
        return updated;
      });
    } else {
      // Mute the patient for 15 minutes
      const muteUntil = Date.now() + 15 * 60 * 1000;
      setMuteTimers((prev) => ({
        ...prev,
        [patientId]: { isMuted: true, muteUntil },
      }));

    }
  };

  useEffect(() => {
    // Periodically check and remove expired mute timers
    const intervalId = setInterval(() => {
      setMuteTimers((prevTimers) => {
        const updatedTimers = {};
        Object.keys(prevTimers).forEach((id) => {
          const { muteUntil } = prevTimers[id];
          if (muteUntil > Date.now()) {
            updatedTimers[id] = prevTimers[id];
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTimeRemaining = (timestamp) => {
    const remaining = timestamp - Date.now();
    if (remaining <= 0) return "00:00";

    const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleCustomizeClick = (e, patient) => {
    e.stopPropagation(); // Prevent the parent div click event
    setSelectedPatient(patient);
    setShowModal(true);
  };

  const handleSaveCustomRange = (vitalRanges) => {
    setCustomRanges((prev) => ({
      ...prev,
      [selectedPatient.patient_id]: vitalRanges,
    }));
    setShowModal(false);
  };

  // Unaasigning patient modal
  const handleUnassignClick = (e, patientCard) => {
    e.stopPropagation(); 
    const { patient_id, device_mac_id, clinic_id } = patientCard;
    setSelectedPatientForUnassign({ patient_id, device_mac_id, clinic_id });
    setShowUnassignModal(true); 
  };


  const handleUnassignPatient = async () => {
    const { patient_id, device_mac_id, clinic_id } = selectedPatientForUnassign;
    try {
      await patientApi.unassignPatient(device_mac_id, patient_id, clinic_id);
      window.location.reload();
    } catch (error) {
    } finally {
      setShowUnassignModal(false);
    }
  };


  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "18px 50px" }}>
      {patientCards.map((patientCard, index) => {
        const muteData = muteTimers[patientCard.patient_id];
        const isMuted = muteData?.isMuted;
        const muteUntil = muteData?.muteUntil;

        return (
          <div
            key={index}
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              padding: '20px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              minHeight: '270px',
              maxHeight: '500px',
              height: 'auto',
              cursor: 'pointer',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              selectPatient(patientCard);
            }}
          >

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ color: "#2e37a4", fontFamily: "Inter", fontSize: "18px", fontWeight: 600, whiteSpace: "nowrap", }}>
                {patientCard?.patient_name}
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {/* Mute Button */}
                <div className="mute-button" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={(e) => {
                  e.stopPropagation();
                  handleMuteClick(patientCard.patient_id); // Mute the patient on button click
                }} >
                  <img src={MuteButton} alt="Mute" style={{ width: "16px", cursor: "pointer", color: "#2e37a4" }} />
                </div>

                {/* Timer Duration */}
                <div style={{
                  display: "inline-block", padding: "3px 7px", backgroundColor: "rgb(238 238 238)", color: "rgb(21 32 169)", fontSize: "10px", borderRadius: "5px", fontWeight: "500",
                  cursor: "pointer", textAlign: "center",
                }} >
                  {formatDuration(timers[patientCard.patient_id])}
                </div>

                {/* Cross Button */}
                <button style={{ border: "none", background: "none" }} onClick={(e) => handleUnassignClick(e, patientCard)} >
                  <img
                    src={Cross} width={22} alt="cross" style={{ cursor: "pointer" }} />
                </button>
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#6b7280', fontSize: '12px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <p style={{ margin: 0 }}>Floor {patientCard?.floor}</p>|<p style={{ margin: 0 }}>Room {patientCard?.room}</p>|
                <p style={{ margin: 0 }}>Bed {patientCard?.bed}</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg
                  width="26"
                  height="17"
                  viewBox="0 0 24 12"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ border: 'none' }}
                >
                  <rect x="1" y="2" width="20" height="8" rx="2" ry="2" fill="#ccc" />
                  <rect
                    x="1"
                    y="3"
                    width={`${(patientCard?.battery || 0) * 0.2}`}
                    height="6"
                    rx="1"
                    ry="1"
                    fill={
                      patientCard?.battery >= 80
                        ? 'green'
                        : patientCard?.battery >= 60
                          ? 'yellowgreen'
                          : patientCard?.battery >= 40
                            ? 'orange'
                            : 'red'
                    }
                  />
                  <rect x="21" y="4" width="2" height="4" rx="1" ry="1" fill="#ccc" />
                </svg>
                <div style={{ fontSize: '12px', color: '#6b7280' }}>
                  {patientCard?.battery ? `${patientCard.battery}%` : ''}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ color: '#6b7280', fontFamily: 'Inter', fontSize: '12px' }}>
                Dr: {patientCard?.doctor_name}
              </div>
              <div
                style={{
                  padding: '2px 12px',
                  backgroundColor:
                    patientCard?.patientDetails?.patientLevel === 1
                      ? '#FFE6E5'
                      : patientCard?.patientDetails?.patientLevel === 2
                        ? '#FFF2E5'
                        : patientCard?.patientDetails?.patientLevel === 3
                          ? '#FDECB4'
                          : 'transparent',
                  color:
                    patientCard?.patientDetails?.patientLevel === 1
                      ? '#E51D00'
                      : patientCard?.patientDetails?.patientLevel === 2
                        ? '#E57300'
                        : patientCard?.patientDetails?.patientLevel === 3
                          ? '#7D6300'
                          : 'transparent',
                  borderRadius: '8px',
                }}
              >
                Level {patientCard?.patientDetails?.patientLevel}
              </div>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', paddingTop: '8px' }}>
              <div style={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', borderRadius: '8px', minHeight: '50px' }}>
                <div style={{
                  background: '#2455AE',
                  padding: '10px',
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  {/* Left section: ECG */}
                  <div style={{ fontSize: '14px', color: '#ffffff' }}>
                    <span>ECG</span>
                  </div>

                  {/* Center section: Tachycardia */}
                  {/* <div style={{ fontSize: '14px', color: '#ffffff', textAlign: 'center', flexGrow: 1 }}>
                  <span>Tachycardia</span>
                </div> */}

                  {/* Right section: LIVE */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#ffffff', backgroundColor: "#ffffff", padding: '1px 7px', borderRadius: '9px' }}>
                    <span style={{ width: '5px', height: '5px', backgroundColor: 'red', borderRadius: '50%' }}></span>
                    <span style={{ fontSize: '12px', color: '#2455AE' }}>LIVE</span>
                  </div>
                </div>

                <ECGGraph patient={patientCard} />
              </div>

              <VitalCard
                patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                vitals={patientCard.vitals}
                label={<img src={vectorIcon} alt='' />}
                value={(patientCard?.vitals?.vitals?.hr && patientCard?.vitals?.vitals?.hr.length > 0)
                  ? patientCard.vitals.vitals.hr[patientCard.vitals.vitals.hr.length - 1]?.v
                  : 'N/A'}
                type="hr"
              />

              <VitalCard
                patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                label={<img src={lungsIcon} width={18} alt='' />}
                value={(patientCard?.vitals?.vitals?.rr && patientCard?.vitals?.vitals?.rr.length > 0)
                  ? patientCard.vitals.vitals.rr[patientCard.vitals.vitals.rr.length - 1]?.v
                  : 'N/A'}
                type="rr"
              />

              <VitalCard
                patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                label={<img src='https://cdn-icons-png.flaticon.com/512/45/45844.png' width={20} alt='' />}
                value={(patientCard?.vitals?.vitals?.spo2 && patientCard?.vitals?.vitals?.spo2.length > 0)
                  ? patientCard.vitals.vitals.spo2[patientCard.vitals.vitals.spo2.length - 1]?.v
                  : 'N/A'}
                type="spo2"
              />

              <VitalCard patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                label={<img src='https://static.thenounproject.com/png/1979336-200.png' width={20} alt='' />}
                value={(patientCard?.vitals?.vitals?.skt && patientCard?.vitals?.vitals?.skt.length > 0)
                  ? (patientCard?.vitals?.vitals?.skt[patientCard?.vitals?.vitals?.skt.length - 1]?.v)
                  : 'N/A'}
                type="skt"
              />

              <VitalCard
                patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                label={<img src={bpIcon} width={24} alt='' />}
                value={(patientCard?.vitals?.vitals?.bp && patientCard?.vitals?.vitals?.bp.length > 0)
                  ? patientCard?.vitals?.vitals?.bp[patientCard?.vitals?.vitals?.bp.length - 1]?.sp : 'N/A'}
                type="SBP"
              />

              <VitalCard patientId={patientCard.patient_id}
                muteTimers={muteTimers}
                customRanges={customRanges[patientCard.patient_id] || {}}
                label={<img src={bpIcon} width={24} alt='' />}
                value={(patientCard?.vitals?.vitals?.bp && patientCard?.vitals?.vitals?.bp.length > 0)
                  ? patientCard?.vitals?.vitals?.bp[patientCard?.vitals?.vitals?.bp.length - 1]?.dp : 'N/A'}
                type="DBP"
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                gap: "15px", // Add space between the buttons
              }}
            >
              {/* Mute Button */}
              <div
                className="mute-button-divs"
                style={{
                  marginBottom: "0px",
                  background: isMuted ? "#a52424" : "#2455AE",
                  fontSize: "10px",
                  color: "#ffffff",
                  borderRadius: "10px",
                  padding: "4px 12px",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMuteClick(patientCard.patient_id);
                }}
              >
                <img
                  src={isMuted ? MuteButton : UnmuteButton}
                  alt={isMuted ? "Mute" : "Unmute"}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                  }}
                />
                <span>
                  {isMuted
                    ? `Muted alerts until ${formatTimeRemaining(muteUntil)}`
                    : "Mute alerts for 15 mins"}
                </span>
              </div>

              {/* Customize Button */}
              <div>
                <button
                  style={{
                    background: isMuted ? "#a52424" : "#2455AE", // Same background as mute button
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "10px", // Same border radius as mute button
                    padding: "4px 12px", // Match padding with mute button
                    fontSize: "12px",
                    fontFamily: "Inter",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleCustomizeClick(e, patientCard)}
                >
                  Manage Alerts
                </button>
              </div>
            </div>

          </div>
        );
      })}
      {showModal && (
        <CustomizeModal
          show={showModal} // Pass this to control the modal's visibility
          onClose={() => setShowModal(false)}
          onSave={handleSaveCustomRange}
          defaultRanges={customRanges[selectedPatient?.patient_id] || {}}
        />
      )}
      {/* Unassign Modal */}
      {showUnassignModal && (
        <Modal show={showUnassignModal} onHide={() => setShowUnassignModal(false)} centered>
          <Modal.Body className="text-center">
            <img src={imagesend} alt="" width={35} height={29} style={{ marginBottom: '10px' }} />
            <h4 style={{ margin: '10px' }}>Are you sure you want to UnAssign this Patient?</h4>
            <div className="m-t-20">
              <button className="btn btn-white me-2" onClick={() => setShowUnassignModal(false)}>
                Close
              </button>
              <button onClick={handleUnassignPatient} className="btn btn-danger">
                Yes
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

    </div>
  );
};

export default CardsGrid;