import React from 'react';
import batteryIcon from "../../../assets/img/battery.svg";
import vectorIcon from '../../../assets/img/Vector.svg';
import lungsIcon from '../../../assets/img/lungs.png';
import bpIcon from "../../../assets/img/Blood_Pressure.svg";
import Cross from "../../../assets/img/x.svg";
import VitalCard from './colorRanges';
import { Modal } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import patientApi from '../../../services/ClientServices/PatientRoutes';
import { imagesend } from '../../imagepath';
import ECGGraph from './EcgGraph';
import MuteButton from "../../../assets/img/mute.png"
import UnmuteButton from "../../../assets/img/unmute.png"
import CustomizeModal from './CustomizeModal';
import { saveCustomVitalRanges } from '../../../utils/customVitalsUtils';

const CardsGrid = ({ patientCards, patientId, selectPatient }) => {
    const [showModal, setShowModal] = useState(false);
    const [showUnassignModal, setShowUnassignModal] = useState(false); // For unassign popup
    const [selectedPatientForUnassign, setSelectedPatientForUnassign] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [customRanges, setCustomRanges] = useState({});
    const [initialDurations, setInitialDurations] = useState({});
    const [timers, setTimers] = useState({});
    const [muteTimers, setMuteTimers] = useState({});


    //count down timer
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimers((prevTimers) => {
                const updatedTimers = {};
                Object.keys(prevTimers).forEach((id) => {
                    if (prevTimers[id] > 0) {
                        updatedTimers[id] = prevTimers[id] - 1; // Decrement the timer
                    } else {
                        updatedTimers[id] = 0; // Ensure it doesn't go below 0
                    }
                });
                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    useEffect(() => {
        // Set initial durations only when patientCards change
        const initialTimers = {};
        patientCards.forEach((patient) => {
            if (!initialDurations[patient.patient_id]) {
                initialTimers[patient.patient_id] = patient.duration;
            }
        });
        setInitialDurations((prev) => ({ ...prev, ...initialTimers }));
        setTimers((prev) => ({ ...prev, ...initialTimers }));
    }, [patientCards]);

    const formatDuration = (duration) => {
        if (!duration) return "00:00";
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        const formattedHours = hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };
    //count down timer - end


    //mute button 
    const handleMuteClick = (patientId) => {
        const isMuted = muteTimers[patientId]?.isMuted;
        if (isMuted) {
            // Unmute the patient
            setMuteTimers((prev) => {
                const updated = { ...prev };
                delete updated[patientId];
                return updated;
            });
        } else {
            // Mute the patient for 15 minutes
            const muteUntil = Date.now() + 15 * 60 * 1000;
            setMuteTimers((prev) => ({
                ...prev,
                [patientId]: { isMuted: true, muteUntil },
            }));

        }
    };

    useEffect(() => {
        // Periodically check and remove expired mute timers
        const intervalId = setInterval(() => {
            setMuteTimers((prevTimers) => {
                const updatedTimers = {};
                Object.keys(prevTimers).forEach((id) => {
                    const { muteUntil } = prevTimers[id];
                    if (muteUntil > Date.now()) {
                        updatedTimers[id] = prevTimers[id];
                    }
                });
                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTimeRemaining = (timestamp) => {
        const remaining = timestamp - Date.now();
        if (remaining <= 0) return "00:00";

        const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };


    const handleUnassignClick = (e, patientCard) => {
        e.stopPropagation(); // Prevent card click event from triggering
        const { patient_id, device_mac_id, clinic_id } = patientCard;
        setSelectedPatientForUnassign({ patient_id, device_mac_id, clinic_id });
        setShowModal(true);
    };

    const handleUnassignPatient = async () => {
        const { patient_id, device_mac_id, clinic_id } = selectedPatientForUnassign;
        try {
            await patientApi.unassignPatient(device_mac_id, patient_id, clinic_id);
            window.location.reload();
        } catch (error) {
        } finally {
            setShowUnassignModal(false);
        }
    };
    const fetchCustomVitals = useCallback(async () => {
        // Keep track of mounted state
        let isMounted = true;

        try {
            // Use Set to track processed IDs and avoid duplicates
            const processedIds = new Set();

            const promises = patientCards.map(async (patient) => {
                // Skip if patient ID is missing or already processed
                if (!patient?.pid || processedIds.has(patient.pid)) {
                    return;
                }

                processedIds.add(patient.pid);

                try {
                    const response = await patientApi.getCustomVitals(patient.pid);

                    // Only update state if component is still mounted
                    if (isMounted && response.status === 200 && response.vitalsRange) {
                        setCustomRanges(prev => ({
                            ...prev,
                            [patient.pid]: {
                                hr: response.vitalsRange.hr,
                                rr: response.vitalsRange.rr,
                                dbp: response.vitalsRange.dbp,
                                hrv: response.vitalsRange.hrv,
                                sbp: response.vitalsRange.sbp,
                                skt: response.vitalsRange.skt,
                                spo2: response.vitalsRange.sp02
                            }
                        }));
                    }
                } catch (error) {
                    console.error(`Error fetching vitals for patient ${patient.pid}:`, error);
                }
            });

            await Promise.all(promises);
        } catch (error) {
            console.error('Error fetching custom vitals:', error);
        }

        // Cleanup function to prevent memory leaks
        return () => {
            isMounted = false;
        };
    }, [patientCards]); // Only depends on patientCards

    useEffect(() => {
        if (patientCards?.length > 0) {
            const cleanup = fetchCustomVitals();
            return () => cleanup;
        }
    }, [fetchCustomVitals]); // Only re-run when fetchCustomVitals changes

    const handleCustomizeClick = (e, patient) => {
        e.stopPropagation();
        setSelectedPatient({
            ...patient,
            patient_id: patient.pid  // Ensure patient_id is set from pid
        });
        setShowModal(true);
    };

    const handleSaveCustomRange = async (vitalRanges) => {
        await saveCustomVitalRanges(
            selectedPatient,
            vitalRanges,
            setCustomRanges,
            setShowModal,
            fetchCustomVitals  // Pass the fetch function to refresh data after save
        );
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '18px 50px' }}>
            {patientCards.map((patient) => {
                const muteData = muteTimers[patient.patient_id];
                const isMuted = muteData?.isMuted;
                const muteUntil = muteData?.muteUntil;

                return (
                    <div
                        key={patient.pid}
                        style={{
                            backgroundColor: '#ffffff',
                            borderRadius: '8px',
                            padding: '20px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            minHeight: '270px',
                            maxHeight: '500px',
                            height: 'auto',
                            cursor: 'pointer',
                            justifyContent: 'space-between',
                        }}
                    // onClick={() => {
                    //     selectPatient(patient);
                    // }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ color: "#2e37a4", fontFamily: "Inter", fontSize: "18px", fontWeight: 600, whiteSpace: "nowrap", }}>
                                {patient?.patient_name}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                {/* Timer Duration */}
                                <div style={{
                                    display: "inline-block", padding: "3px 7px", backgroundColor: "rgb(238 238 238)", color: "rgb(21 32 169)", fontSize: "10px", borderRadius: "5px", fontWeight: "500",
                                    cursor: "pointer", textAlign: "center",
                                }} >
                                    {formatDuration(timers[patient.patient_id])}
                                </div>

                                {/* Cross Button */}
                                <button style={{ border: "none", background: "none" }} onClick={(e) => handleUnassignClick(e, patient)} >
                                    <img
                                        src={Cross} width={22} alt="cross" style={{ cursor: "pointer" }} />
                                </button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#6b7280', fontSize: '12px', marginBottom: '5px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <p style={{ margin: 0, color: '#6b7280', fontFamily: 'Inter', fontSize: '12px' }}>{patient?.patient_accommodation}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ color: '#6b7280', fontFamily: 'Inter', fontSize: '12px' }}>
                                    Dr: {patient?.doctor_name}
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <svg
                                    width="26"
                                    height="17"
                                    viewBox="0 0 24 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ border: 'none' }}
                                >
                                    <rect x="1" y="2" width="20" height="8" rx="2" ry="2" fill="#ccc" />
                                    <rect
                                        x="1"
                                        y="3"
                                        width={`${(patient?.b || 0) * 0.2}`}
                                        height="6"
                                        rx="1"
                                        ry="1"
                                        fill={
                                            patient?.b >= 80
                                                ? 'green'
                                                : patient?.b >= 60
                                                    ? 'yellowgreen'
                                                    : patient?.b >= 40
                                                        ? 'orange'
                                                        : 'red'
                                        }
                                    />
                                    <rect x="21" y="4" width="2" height="4" rx="1" ry="1" fill="#ccc" />
                                </svg>
                                <div style={{ fontSize: '12px', color: '#6b7280' }}>
                                    {patient?.b ? `${patient.b}%` : ''}
                                </div>
                            </div>
                        </div>


                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', paddingTop: '8px' }}>
                            <div style={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', borderRadius: '8px', minHeight: '50px' }}>
                                <div style={{
                                    background: '#2455AE',
                                    padding: '10px',
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    {/* Left section: ECG */}
                                    <div style={{ fontSize: '14px', color: '#ffffff' }}>
                                        <span>ECG</span>
                                    </div>


                                    {/* Center section: Tachycardia */}
                                    <div style={{ fontSize: '14px', color: '#ffffff', textAlign: 'center', flexGrow: 1 }}>
                                        <span>{patient?.vitals?.vitals?.arrhythmias?.v || ''}</span>
                                    </div>

                                    {/* Right section: LIVE */}
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#ffffff', backgroundColor: "#ffffff", padding: '1px 7px', borderRadius: '9px' }}>
                                        <span style={{ width: '5px', height: '5px', backgroundColor: 'red', borderRadius: '50%' }}></span>
                                        <span style={{ fontSize: '12px', color: '#2455AE' }}>LIVE</span>
                                    </div>
                                </div>

                                <ECGGraph patient={patient} />
                            </div>
                            <VitalCard
                                patientId={patient.pid}
                                patientName={patient.patient_name}
                                muteTimers={muteTimers}
                                customRanges={customRanges[patient.patient_id] || {}}
                                vitals={patient.vitals}
                                label={<img src={vectorIcon} alt='' />}
                                value={patient?.vitals?.hr?.[0]?.v || "N/A"}
                                type="HR"
                            />

                            <VitalCard patientId={patient.pid} patientName={patient.patient_name} muteTimers={muteTimers}
                                customRanges={customRanges[patient.patient_id] || {}}
                                vitals={patient.vitals}
                                label={<img src={lungsIcon} width={18} alt='' />}
                                value={patient?.vitals?.rr?.[0]?.v || "N/A"}
                                type="RR"
                            />

                            <VitalCard
                                patientId={patient.pid}
                                patientName={patient.patient_name}
                                muteTimers={muteTimers}
                                customRanges={customRanges[patient.pid] || {}}
                                vitals={patient.vitals}
                                label={<img src="https://cdn-icons-png.flaticon.com/512/45/45844.png" width={20} alt="SpO2" />}
                                value={patient?.vitals?.spo2?.[0]?.v  || "N/A"}
                                type="SPO2"
                            />

                            <VitalCard
                                patientId={patient.pid}
                                patientName={patient.patient_name}
                                muteTimers={muteTimers}
                                customRanges={customRanges[patient.pid] || {}}
                                vitals={patient.vitals}
                                label={<img src="https://static.thenounproject.com/png/1979336-200.png" width={20} alt="Skin Temperature" />}
                                value={patient?.vitals?.skt?.[0]?.v || "N/A"}
                                type="SKT"
                            />
                            <VitalCard
                                patientId={patient.pid}
                                patientName={patient.patient_name}
                                muteTimers={muteTimers}
                                customRanges={customRanges[patient.pid] || {}}
                                vitals={patient?.vitals?.vitals?.sp?.[0]?.v }
                                label={<img src={bpIcon} width={24} alt="Systolic BP" />}
                                value={patient?.vitals?.vitals?.sp?.[0]?.v || "N/A"}
                                type="SBP"
                            />

                            <VitalCard
                                patientId={patient.pid}
                                patientName={patient.patient_name}
                                muteTimers={muteTimers}
                                customRanges={customRanges[patient.pid] || {}}
                                vitals={patient.vitals}
                                label={<img src={bpIcon} width={24} alt="Diastolic BP" />}
                                value={patient?.vitals?.vitals?.dp?.[0]?.v || "N/A"}
                                type="DBP"
                            />
                        </div>


                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                                gap: "15px", // Add space between the buttons
                            }}
                        >
                            {/* Mute Button */}
                            <div
                                className="mute-button-divs"
                                style={{
                                    marginBottom: "0px",
                                    background: isMuted ? "#a52424" : "#2455AE",
                                    fontSize: "10px",
                                    color: "#ffffff",
                                    borderRadius: "10px",
                                    padding: "4px 12px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleMuteClick(patient.patient_id);
                                }}
                            >
                                <img
                                    src={isMuted ? MuteButton : UnmuteButton}
                                    alt={isMuted ? "Mute" : "Unmute"}
                                    style={{
                                        width: "16px",
                                        cursor: "pointer",
                                    }}
                                />
                                <span>
                                    {isMuted
                                        ? `Muted alerts until ${formatTimeRemaining(muteUntil)}`
                                        : "Mute alerts for 15 mins"}
                                </span>
                            </div>

                            {/* Customize Button */}
                            <div>
                                <button
                                    style={{
                                        background: isMuted ? "#a52424" : "#2455AE", // Same background as mute button
                                        color: "#ffffff",
                                        border: "none",
                                        borderRadius: "10px", // Same border radius as mute button
                                        padding: "4px 12px", // Match padding with mute button
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => handleCustomizeClick(e, patient)}
                                >
                                    Manage Alerts
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
            {showModal && (
                <CustomizeModal
                    show={showModal} // Pass this to control the modal's visibility
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveCustomRange}
                    defaultRanges={customRanges[selectedPatient.pid] || {}}
                />
            )}
            {/* Unassign Modal */}
            {showUnassignModal && (
                <Modal show={showUnassignModal} onHide={() => setShowUnassignModal(false)} centered>
                    <Modal.Body className="text-center">
                        <img src={imagesend} alt="" width={35} height={29} style={{ marginBottom: '10px' }} />
                        <h4 style={{ margin: '10px' }}>Are you sure you want to UnAssign this Patient?</h4>
                        <div className="m-t-20">
                            <button className="btn btn-white me-2" onClick={() => setShowUnassignModal(false)}>
                                Close
                            </button>
                            <button onClick={handleUnassignPatient} className="btn btn-danger">
                                Yes
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default CardsGrid;