import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DonutChart from "./DonutChart";
import { calendar, empty_wallet, morning_img_01, profile_add, scissor, user001, invoice_icon1, invoice_icon2, invoice_icon3 } from "../../imagepath";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { connect } from "react-redux";
import dashboardApi from "../../../services/dahsboard/dashboard";
import { DatePicker } from "antd";
import ClientsTable from "./ClientsTable";

const DeepFacts_Dashboard = ({ user }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [showPage, setShowPage] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [patientsData, setPatientsData] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const fetchData = async () => {
    if (startDate && endDate) {
      const body = {
        duration: 300,
        start_date: startDate ? startDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
        end_date: endDate ? endDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
      };

      try {
        const resp = await dashboardApi.getAdminDashboardData(body);
        if (resp) {
          console.log("Dashboard Data:", resp); // Log the response
          setDashboardData(resp.deepfacts_dashboard);
          setPatientsData(resp.deepfacts_dashboard.patients_plot);
          setShowPage(true);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    }
  };

  // Trigger fetchData when both start and end dates are selected
  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]); 

  // Transform devices_category into the required format for DonutChart
  const devicesCategoryData = {};
  if (dashboardData.devices_category) {
    dashboardData.devices_category.forEach(([label, value]) => {
      devicesCategoryData[label] = value;
    });
  }

  return (
    <>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-8">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">DeepFacts Dashboard</li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <div className="d-flex justify-content-end">
                    <div className="form-group mr-2" style={{ marginRight: '10px' }}>
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          selected={startDate}
                          onChange={handleStartDateChange}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="date-picker"
                          style={{
                            borderColor: startDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                          }}
                          placeholder="Start Date"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          selected={endDate}
                          onChange={handleEndDateChange}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="date-picker"
                          style={{
                            borderColor: endDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                          }}
                          placeholder="End Date"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam!" : user?.name}</span>
                    </h2>
                    {/*<p>Have a nice day at work</p>*/}
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                      alt="#" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={calendar} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Total Clients</h4>
                    <h2>
                      <CountUp delay={0.6} end={dashboardData.total_clients} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        {dashboardData.total_clients_active || 0} Active
                      </span>{" "}
                      vs <span className="negative-view">
                        {" "} {dashboardData.total_clients_inactive || 0} InActive </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={profile_add} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Total Clinics</h4>
                    <h2>
                      <h2>
                        <CountUp delay={0.4} end={dashboardData.total_clinics} duration={0.6} />
                      </h2>
                    </h2>
                    <p>
                      <span className="passive-view">
                        {dashboardData.total_clinics_active || 0} Active
                      </span>{" "}
                      vs
                      <span className="negative-view">
                        {" "}  {dashboardData.total_clinics_inactive || 0} InActive
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={scissor} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4> Total Doctors</h4>
                    <h2>
                      <CountUp delay={0.4} end={dashboardData.total_doctors} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        {dashboardData.total_doctors_active || 0} Active
                      </span>{" "}
                      vs
                      <span className="negative-view">
                        {" "}  {dashboardData.total_doctors_inactive || 0} InActive
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={empty_wallet} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Total Patients</h4>
                    <h2>
                      <CountUp delay={0.4} end={dashboardData.total_patients} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        0 Active
                      </span>{" "}
                      vs
                      <span className="negative-view"> {" "}
                        0 InActive
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Donut Chart Column */}
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 d-flex">
                <div className="card w-100" >
                  <div className="card-body" style={{ maxHeight: "250px" }}>
                    <div className="inovices-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p className="inovices-all" style={{ marginTop: '0px' }}>Devices By Category</p>
                      <span className="inovices-widget-icon">
                        <img src={invoice_icon3} alt="#" style={{ width: '40px', height: '40px' }} />
                      </span>
                    </div>
                    <div id="donut-chart-dash" className="chart-user-icon">
                      <DonutChart chartData={devicesCategoryData} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Clients Table Column */}
              <ClientsTable patientsData={patientsData} />

              {/* Reports Data */}
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="card inovices-card w-100" style={{ minHeight: '250px' }}>
                  <div className="card-body">
                    <div className="inovices-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p className="inovices-all" style={{ marginTop: '0px' }}>Reports Generated</p>
                      <span className="inovices-widget-icon">
                        <img src={invoice_icon1} alt="#" style={{ width: '40px', height: '40px' }} />
                      </span>
                    </div>

                    <td className="table-image appoint-doctor">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <p className="inovices-all" style={{ fontSize: '14px' }}>15 mins</p>
                        <p className="inovices-all" style={{ color: '#2e37a4', fontWeight: '500', fontSize: '14px', margin: 0 }}>{dashboardData.reports_generated_15mins
                        }</p>
                      </div>
                    </td>

                    <td className="table-image appoint-doctor">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <p className="inovices-all" style={{ fontSize: '14px' }}>24 hrs</p>
                        <p className="inovices-all" style={{ color: '#2e37a4', fontWeight: '500', fontSize: '14px', margin: 0 }}>{dashboardData.reports_generated_inpatient_24hrs}</p>
                      </div>
                    </td>


                    <td className="table-image appoint-doctor">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <p className="inovices-all" style={{ fontSize: '14px' }}>Out Patient</p>
                        <p className="inovices-all" style={{ color: '#2e37a4', fontWeight: '500', fontSize: '14px', margin: 0 }}>{dashboardData.reports_generated_outpatient
                        }</p>
                      </div>
                    </td>

                    <td className="table-image appoint-doctor">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <p className="inovices-all" style={{ fontSize: '14px' }}>Total Reports</p>
                        <p className="inovices-all" style={{ color: '#2e37a4', fontWeight: '500', fontSize: '14px', margin: 0 }}>{dashboardData.total_reports}</p>
                      </div>
                    </td>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.userData,
  authenticated: state.auth.authenticated,
  token: state.auth.token,
  client_id: state.auth.client_id,
  clinic_id: state.auth.clinic_id
});
export default connect(mapStateToProps)(DeepFacts_Dashboard);
