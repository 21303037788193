import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import vectorIcon from '../../../assets/img/Vector.svg';
import lungsIcon from '../../../assets/img/lungs.png';
import bpIcon from "../../../assets/img/Blood_Pressure.svg";

const CustomizeModal = ({ show, onClose, onSave, defaultRanges }) => {
  const [ranges, setRanges] = useState(defaultRanges);

  const handleInputChange = (e, vital) => {
    const { name, value } = e.target;
    setRanges((prev) => ({
      ...prev,
      [vital]: { ...prev[vital], [name]: value },
    }));
  };

  const handleSave = () => {
    onSave(ranges);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#2e37a4" }}>Customize Patient Vitals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="d-flex justify-content-between mb-3" style={{ gap: "20px" }}>
            {/* Heart Rate Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="heartRate" className="form-label d-flex align-items-center">
                <img
                  src={vectorIcon}
                  alt=""
                  style={{ width: "15px", marginRight: "8px" }}
                />
                Heart Rate
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Hr"
                    name="min"
                    value={ranges.hr?.min || ""}
                    onChange={(e) => handleInputChange(e, "hr")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Hr"
                    name="max"
                    value={ranges.hr?.max || ""}
                    onChange={(e) => handleInputChange(e, "hr")}
                  />
                </div>
              </div>
            </div>

            {/* Respiratory Rate Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="heartRate" className="form-label d-flex align-items-center">
                <img
                  src={lungsIcon}
                  alt=""
                  style={{ width: "20px", marginRight: "8px" }}
                />
                Respiratory Rate
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Rr"
                    name="min"
                    value={ranges.rr?.min || ""}
                    onChange={(e) => handleInputChange(e, "rr")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Rr"
                    name="max"
                    value={ranges.rr?.max || ""}
                    onChange={(e) => handleInputChange(e, "rr")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" style={{ gap: "20px" }}>
            {/* Oxygen Saturation Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="oxygenSaturation" className="form-label d-flex align-items-center">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/45/45844.png"
                  width={20}
                  alt=""
                  style={{ marginRight: "8px" }}
                />
                Oxygen Saturation
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Spo2"
                    name="min"
                    value={ranges.spo2?.min || ""}
                    onChange={(e) => handleInputChange(e, "spo2")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Spo2"
                    name="max"
                    value={ranges.spo2?.max || ""}
                    onChange={(e) => handleInputChange(e, "spo2")}
                  />
                </div>
              </div>
            </div>

            {/* Skin Temperature Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="Skin Temperature" className="form-label d-flex align-items-center">
                <img
                  src="https://static.thenounproject.com/png/1979336-200.png"
                  width={20}
                  alt=""
                  style={{ marginRight: "8px" }}
                />
                Skin Temperature
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Skt"
                    name="min"
                    value={ranges.skt?.min || ""}
                    onChange={(e) => handleInputChange(e, "skt")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Skt"
                    name="max"
                    value={ranges.skt?.max || ""}
                    onChange={(e) => handleInputChange(e, "skt")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" style={{ gap: "20px" }}>
            {/* Systolic Blood Pressure Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="heartRate" className="form-label">
                <img
                  src={bpIcon}
                  alt=""
                  style={{ width: "20px", marginRight: "8px" }}
                />
                Systolic Blood Pressure
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Sbp"
                    name="min"
                    value={ranges.sbp?.min || ""}
                    onChange={(e) => handleInputChange(e, "sbp")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Sbp"
                    name="max"
                    value={ranges.sbp?.max || ""}
                    onChange={(e) => handleInputChange(e, "sbp")}
                  />
                </div>
              </div>
            </div>

            {/* Diastolic Blood Pressure Section */}
            <div style={{ flex: 1 }}>
              <label htmlFor="heartRate" className="form-label">
                <img
                  src={bpIcon}
                  alt=""
                  style={{ width: "20px", marginRight: "8px" }}
                />
                Diastolic Blood Pressure
              </label>
              <div className="row mb-2">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="min Dbp"
                    name="min"
                    value={ranges.dbp?.min || ""}
                    onChange={(e) => handleInputChange(e, "dbp")}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="max Dbp"
                    name="max"
                    value={ranges.dbp?.max || ""}
                    onChange={(e) => handleInputChange(e, "dbp")}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleSave}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomizeModal;