/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import clientApi from "../../services/ClientServices/ClientRoutes";
import { Modal } from "bootstrap";
import { useClient } from '../../context/ClientContext';
import { useLocation } from 'react-router-dom';
import DeleteIcon from "../../assets/img/delete.png";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import CardLayout from "../form_components/CardLayout";
import FormSection from "../form_components/FormSection";
import { clientFormFields } from "../form_components/FormFields";
import PageLayout from "../form_components/PageLayout";
import ImagePreviewInput from "../form_components/ImagePreviewInput";
import { departmentOptions, specialityOptions, selectStyles, useFormSelect, FormSelect } from "../form_components/DropdownOptions";

const EditClient = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPictureUpdated, setIsPictureUpdated] = useState(false);
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [isSignatureUpdated, setIsSignatureUpdated] = useState(false);
  const { clientId } = useClient();
  const location = useLocation();
  const isViewMode = location.state?.type === 'view';
  const [activeTab, setActiveTab] = useState('general');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [picturePreview, setPicturePreview] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);

  const blankProfileImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Cpath d='M100 60a20 20 0 1 1 0 40 20 20 0 0 1 0-40zm0 55c25 0 45 20 45 45H55c0-25 20-45 45-45' fill='%23cccccc'/%3E%3C/svg%3E";

  const [selectedDepartment, setSelectedDepartment, handleDepartmentChange] =
    useFormSelect(null);
  const [selectedSpeciality, setSelectedSpeciality, handleSpecialityChange] =
    useFormSelect(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formData, setFormData] = useState({
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    pan_number: "",
    facebook_id: "",
    twitter_id: "",
    linkedin_id: "",
    instagram_id: "",
    address: "",
    pin_code: "",
    city: "",
    incorp_date: "",
    state: "",
    country: "",
    mobile: "",
    email: "",
    first_name: "",
    last_name: "",
    speciality: "",
    hospital_name: "",
    license_number: "",
    departments: [],
    status: "",
    logo: null,
    picture: '',
    signature: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Safely map fields
  const getFieldsWithValues = (fields) => {
    if (!Array.isArray(fields)) {
      console.error("Invalid fields:", fields);
      return []; // Return an empty array to avoid runtime errors
    }

    return fields.map(field => ({
      ...field,
      value: field.name === 'full_name'
        ? `${formData.first_name} ${formData.last_name}`
        : formData[field.name] || '' // Default to an empty string if value is undefined
    }));
  };

  // Example usage
  const formFields = {
    personal: getFieldsWithValues(clientFormFields.personal),
    address: getFieldsWithValues(clientFormFields.address),
    social: getFieldsWithValues(clientFormFields.social),
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Handle file changes for all image types
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        switch (name) {
          case 'picture':
            setPicturePreview(result);
            setFormData(prev => ({ ...prev, picture: file }));
            setIsPictureUpdated(true);
            break;
          case 'logo':
            setLogoPreview(result);
            setFormData(prev => ({ ...prev, logo: file }));
            setIsLogoUpdated(true);
            break;
          case 'signature':
            setSignaturePreview(result);
            setFormData(prev => ({ ...prev, signature: file }));
            setIsSignatureUpdated(true);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await clientApi.readClient(clientId);
        const clientDetails = response.clients[0];
        setFormData(clientDetails);
        setSelectedDepartment(
          (clientDetails.departments || []).map((dept) => ({
            value: dept,
            label: dept,
          }))
        );
        setSelectedSpeciality({
          value: clientDetails.speciality, label: clientDetails.speciality
        });

        // Set previews from existing URLs
        if (response.picture_url_expiry) {
          setPicturePreview(response.picture_url_expiry);
        }
        if (response.logo_url_expiry) {
          setLogoPreview(response.logo_url_expiry);
        }
        if (response.signature_url_expiry) {
          setSignaturePreview(response.signature_url_expiry);
        }

      } catch (error) {
        console.error('Error fetching client data:', error);
        setError('Failed to load client data');
        setLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  const profilePicture = picturePreview || blankProfileImage;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    const clientRequest = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (key === 'departments') {
          clientRequest[key] = formData[key];
        } else if (key === 'pin_code') {
          clientRequest[key] = parseInt(formData[key], 10);
        } else if (key === 'incorp_date') {
          clientRequest[key] = formData[key];
        } else {
          clientRequest[key] = formData[key];
        }
      }
    });

    data.append('client_request', JSON.stringify(clientRequest));

    // Only append files if they have been updated
    if (isPictureUpdated && formData.picture) data.append('picture', formData.picture);
    if (isLogoUpdated && formData.logo) data.append('logo', formData.logo);
    if (isSignatureUpdated && formData.signature) data.append('signature', formData.signature);

    try {
      const response = await clientApi.updateClient(clientId, data);
      setIsSuccessful(true);
      setPopupMessage(response.message);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error updating client:', error.response ? error.response.data : error.message);
      setPopupMessage('Failed to update client. Please try again.');
      setIsSuccessful(false);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/client-list');
    }
  };

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setIsClicked(true);
  };

  const [show, setShow] = useState(false);

  return (
    <div>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Clients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Client</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {isViewMode ? (
              <div className="row mt-4">
                <div className="col-xl-3 col-md-4">
                  <div className="card shadow-sm" style={{ padding: '0' }}>
                    <div className="p-4">
                      <div className="text-center mb-3">
                        <div className="avatar-container" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                          <img
                            src={picturePreview || blankProfileImage}
                            alt="Client's Profile"
                            className="rounded-circle w-100 h-100"
                            style={{ objectFit: 'cover' }}
                            onError={(e) => {
                              e.target.src = blankProfileImage;
                            }}
                          />
                        </div>
                      </div>
                      <div className="profile-details">
                        <h5 className="text-center">
                          Dr. {formData.first_name} {formData.last_name}
                        </h5>
                        <p className="text-center mb-0" style={{ fontSize: '13px', color: '#515365' }}>
                          <span> {formData.hospital_name} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <PageLayout>
                  {activeTab === 'general' && (
                    <CardLayout title="General Details">
                      <FormSection
                        fields={getFieldsWithValues(clientFormFields.personal)}
                        onChange={handleInputChange}
                      />
                      <FormSection
                        fields={getFieldsWithValues(clientFormFields.professional)}
                        onChange={handleInputChange}
                      />
                      <FormSection
                        fields={getFieldsWithValues(clientFormFields.address)}
                        onChange={handleInputChange}
                      />
                      <div className="row align-items-center form-group" style={{ marginBottom: '0px' }}>
                        <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'flex-end', gap: '40px', padding: '20px' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div
                              className="avatar-container"
                              style={{
                                width: '180px',
                                height: '90px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                backgroundColor: '#f8f9fa'
                              }}
                            >
                              <img
                                src={logoPreview}
                                alt="Client's Logo"
                                className="w-100 h-100"
                                style={{
                                  objectFit: 'contain'
                                }}
                                onError={(e) => {
                                  e.target.src = blankProfileImage;
                                }}
                              />
                            </div>
                            <label
                              htmlFor="text"
                              className="mt-2 col-form-label input-label"
                            >
                              Client Logo
                            </label>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div
                              className="avatar-container"
                              style={{
                                width: '180px',
                                height: '90px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                backgroundColor: '#f8f9fa'
                              }}
                            >
                              <img
                                src={signaturePreview}
                                alt="Client's Signature"
                                className="w-100 h-100"
                                style={{
                                  objectFit: 'contain'
                                }}
                                onError={(e) => {
                                  e.target.src = blankProfileImage;
                                }}
                              />
                            </div>
                            <label
                              htmlFor="text"
                              className="mt-2 col-form-label input-label"
                            >
                              Client Signature
                            </label>
                          </div>
                        </div>
                      </div>
                    </CardLayout>
                  )}
                  {activeTab === 'social' && (
                    <CardLayout title="Social Media Settings">
                      <FormSection
                        fields={getFieldsWithValues(clientFormFields.social)}
                        onChange={handleInputChange}
                      />
                    </CardLayout>
                  )}
                </PageLayout>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Client Details</h4>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                First Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Last Name <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Mobile <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>Email</label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms cal-icon">
                              <label>
                                Date Of Registration <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control datetimepicker"
                                value={formData.incorp_date}
                                name="incorp_date"
                                disabled
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Hospital Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="hospital_name"
                                className="form-control"
                                type="text"
                                value={formData.hospital_name}
                                onChange={handleChange}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <FormSelect
                              label="Department"
                              name="departments"
                              value={selectedDepartment}
                              onChange={(selected) => handleDepartmentChange(
                                selected,
                                formData,
                                setFormData,
                                'departments'
                              )}
                              options={departmentOptions}
                              isMulti
                              isCreatable
                              required
                            />
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                License Number <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="license_number"
                                value={formData.license_number}
                                onChange={handleChange}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <FormSelect
                              label="Speciality"
                              name="speciality"
                              value={selectedSpeciality}
                              onChange={(selected) => handleSpecialityChange(
                                selected,
                                formData,
                                setFormData,
                                'speciality'
                              )}
                              options={specialityOptions}
                              required
                            />
                          </div>

                          <div className="row" style={{ marginLeft: '0px', marginRight: '0px', padding: '0px' }}>
                            <div className="col-12 col-md-4">
                              <ImagePreviewInput
                                label="Hospital Picture"
                                name="picture"
                                onChange={handleFileChange}
                                initialPreview={picturePreview}
                                defaultImage={blankProfileImage}
                                onImageUpdate={(name, isUpdated) => setIsPictureUpdated(isUpdated)}
                                variant="detailed"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <ImagePreviewInput
                                label="Hospital Logo"
                                name="logo"
                                onChange={handleFileChange}
                                initialPreview={logoPreview}
                                defaultImage={blankProfileImage}
                                onImageUpdate={(name, isUpdated) => setIsLogoUpdated(isUpdated)}
                                variant="detailed"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <ImagePreviewInput
                                label="Authorized Signature"
                                name="signature"
                                onChange={handleFileChange}
                                initialPreview={signaturePreview}
                                defaultImage={blankProfileImage}
                                onImageUpdate={(name, isUpdated) => setIsSignatureUpdated(isUpdated)}
                                variant="detailed"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Address
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Postal Code
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="pin_code"
                                value={formData.pin_code}
                                onChange={handleChange}
                                placeholder=""
                                minLength={6}
                                maxLength={6}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                City
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Country
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                State/Province
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Status <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="Active"
                                    className="form-check-input"
                                    checked={formData.status === 'Active'}
                                    onChange={handleChange}
                                  />
                                  Active
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="InActive"
                                    className="form-check-input"
                                    checked={formData.status === 'InActive'}
                                    onChange={handleChange}
                                  />
                                  InActive
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button
                                type="submit"
                                className="btn btn-primary submit-form me-2"
                              >
                                Update
                              </button>
                              <button
                                type="reset"
                                className="btn btn-primary cancel-form"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* TODO -  Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}

      </>
    </div>
  );
};

export default EditClient;

