
import React from 'react';

const CardLayout = ({ title, children }) => (
  <div className="card invoices-settings-card">
    <div className="card-header px-3">
      <h5 className="card-title" style={{color: '#2e37a4'}}>{title}</h5>
    </div>
    <div className="card-body">
      <form className="invoices-settings-form">
        {children}
      </form>
    </div>
  </div>
);

export default CardLayout;