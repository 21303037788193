import React, { useEffect, useState, useRef } from "react";
import CardsGrid from "./patient-info-card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import Patient_Dashboard from "../Patient_Dashboard/Patient_Dashboard";
import { useCallback } from "react";

const TestingClinicalHealthCard = ({ user, token, clinic_id }) => {
    const [patients, setPatients] = useState([]);
    const socketRef = useRef(null);
    const lastStatusRef = useRef({});
    const [selectedPatient, setSelectedPatient] = useState(null);
    const selectedPatientRef = useRef(null); // Add this ref to track selected patient

    const selectPatient = (patient) => {
        console.log('Selecting patient:', patient);
        // Find the most up-to-date patient data
        const currentPatient = patients.find(p => p.pid === patient.pid);
        setSelectedPatient(currentPatient || patient);
        selectedPatientRef.current = currentPatient || patient; // Update ref
    };

    const unselectPatient = () => {
        setSelectedPatient(null);
        selectedPatientRef.current = null; // Clear ref
    };

    useEffect(() => {
        if (token && clinic_id) {
            const socketUrl = `wss://api.dev.deepfacts.io/streaming_data/v2/stream/clinic/${clinic_id}/vitals/rpm_dashboard?token=${token}`;
            openSocket(socketUrl);
        }

        return () => {
            if (socketRef.current) {
                console.log("Closing existing WebSocket connection...");
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [token, clinic_id]);

    const openSocket = (socketUrl) => {
        console.log("Opening WebSocket connection...");
        const socket = new WebSocket(socketUrl);

        socket.onopen = () => {
            console.log("WebSocket connection established.");
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                handleNewData(data);
            } catch (error) {
                console.error("Error parsing WebSocket message:", error);
            }
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed.");
        };

        socketRef.current = socket;
    };


    const getStatusMessage = (status) => {
        const statusMessages = {
            200: "The proRITHM Device is Connected to the App",
            202: "The Device is connected and testing but paused from the App",
            201: "The Device has completed the test",
            203: "The Device has connected to backend and started a test",
            204: "The Device has stopped due to some error",
            401: "Authentication Token has expired",
            410: "The Device is disconnected from the App",
            422: "The Device is not placed properly on the Patient",
            503: "The App is not connected to the internet or there is a poor network connection",
        };
        return statusMessages[status] || null;
    };

    const handleNewData = useCallback((data) => {
        const { pid, vitals, duration, ecg_clean, patient_name, b, bed, doctor_name, height, weight, bmi, status, patient_accommodation } = data;
    
        // Update patients array
        setPatients((prevPatients) => {
            const existingPatientIndex = prevPatients.findIndex((patient) => patient.pid === pid);
            const existingPatient = existingPatientIndex !== -1 ? prevPatients[existingPatientIndex] : {};
    
            const updatedPatient = {
                pid,
                vitals: {
                    hr: vitals?.hr || existingPatient.vitals?.hr || [],
                    rr: vitals?.rr || existingPatient.vitals?.rr || [],
                    spo2: vitals?.spo2 || existingPatient.vitals?.spo2 || [],
                    skt: vitals?.skt || existingPatient.vitals?.skt || [],
                    hrv: vitals?.hrv || existingPatient.vitals?.hrv || [],
                    arrhythmias: vitals?.arrhythmias || existingPatient.vitals?.arrhythmias || [],
                    vitals: {
                        sp: vitals?.bp ? [{ v: vitals.bp[0].sp }] : existingPatient.vitals?.vitals?.sp || [],
                        dp: vitals?.bp ? [{ v: vitals.bp[0].dp }] : existingPatient.vitals?.vitals?.dp || []
                    }
                },
                duration: duration || existingPatient.duration || null,
                ecg_clean: ecg_clean || existingPatient.ecg_clean || null,
                patient_name: patient_name || existingPatient.patient_name || "",
                b: b || existingPatient.b || "",
                bed: bed || existingPatient.bed || "",
                doctor_name: doctor_name || existingPatient.doctor_name || "",
                height: height || existingPatient.height || null,
                weight: weight || existingPatient.weight || null,
                bmi: bmi || existingPatient.bmi || null,
                patient_accommodation: patient_accommodation || existingPatient.patient_accommodation || null
            };

            if (existingPatientIndex !== -1) {
                const newPatients = [...prevPatients];
                newPatients[existingPatientIndex] = updatedPatient;
    
                // Handle status updates and notifications
                if (status && lastStatusRef.current[pid] !== status) {
                    const statusMessage = getStatusMessage(status);
                    if (statusMessage) {
                        lastStatusRef.current[pid] = status;
    
                        // Play alert sound
                        const audio = new Audio("/sounds/alert_message.mp3");
                        audio.play().catch((error) => console.error("Audio play failed:", error));
    
                        // Show toast notification
                        toast.info(`${patient_name || "Patient"}: ${statusMessage}`, {
                            position: "top-right",
                            autoClose: 10000,
                        });
                    }
                }
    
                return newPatients;
            } else {
                return [...prevPatients, updatedPatient];
            }
        });
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin-dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Remote Patient Monitoring</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {patients.length > 0 && !selectedPatient && (
                        <CardsGrid patientCards={patients} selectPatient={selectPatient} />
                    )}
                </div>
            </div>
            {/* {selectedPatient && (
                <Patient_Dashboard
                    patient={selectedPatient}
                    unselectPatient={unselectPatient}
                    user={user}
                />
            )} */}
        </>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    clinic_id: state.auth.clinic_id
});

export default connect(mapStateToProps)(TestingClinicalHealthCard);