/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import clientApi from "../../services/ClientServices/ClientRoutes";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import usePostalCode from "../../hooks/usePostalCode";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";
import ImagePreviewInput from "../form_components/ImagePreviewInput";
import { departmentOptions, specialityOptions, selectStyles, useFormSelect, FormSelect } from "../form_components/DropdownOptions";

const AddClient = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  const [selectedDepartment, setSelectedDepartment, handleDepartmentChange] =
    useFormSelect(null);
  const [selectedSpeciality, setSelectedSpeciality, handleSpecialityChange] =
    useFormSelect(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleDateOfBirthChange = (date) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD'); // Store as YYYY-MM-DD
      setSelectedDateOfBirth(date);
      setFormData({
        ...formData,
        incorp_date: formattedDate,
      });
    } else {
      setSelectedDateOfBirth(null);
      setFormData({
        ...formData,
        incorp_date: null,
      });
    }
  };

  const [formData, setFormData] = useState({
    account_holder_name: "", account_number: "", ifsc_code: "", bank_branch: "", pan_number: "", facebook_id: "", twitter_id: "", linkedin_id: "",
    instagram_id: "", address: "", pin_code: "", city: "", state: "", country: "", mobile: "", email: "", first_name: "", last_name: "",
    speciality: "", hospital_name: "", license_number: "", departments: [], incorp_date: "", status: "", logo: null,
    picture: null, signature: null,
  });

  const { handlePincodeChange, cityOptions, stateOptions, countryOptions,
    handleCityChange, handleStateChange, handleCountryChange } = usePostalCode();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    const clientRequest = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (key === 'departments') {
          // Handle departments as an array
          clientRequest[key] = formData[key];
        } else if (key === 'pin_code') {
          // Convert pin_code to integer
          clientRequest[key] = parseInt(formData[key], 10);
        } else if (key === 'incorp_date') {
          clientRequest[key] = formData[key]
        }
        else {
          clientRequest[key] = formData[key];
        }
      }
    });

    // Append the client_request JSON string to FormData
    data.append('client_request', JSON.stringify(clientRequest));

    // Append files separately
    if (formData.logo) data.append('logo', formData.logo);
    if (formData.picture) data.append('picture', formData.picture);
    if (formData.signature) data.append('signature', formData.signature);

    try {
      const response = await clientApi.createClient(data);

      if (response.status === 409) {
        setPopupMessage(response.message);
        setIsSuccessful(false);
      } else {
        setPopupMessage(response.message);
        setIsSuccessful(true);
      }

      setPopupMessage(response.message);

      // Show the modal with Bootstrap JavaScript
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setPopupMessage('Unknown server error occurred, Please contact support.');
      } else {
        setPopupMessage('Failed to create Client. Please try again.');
      }

      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setIsClicked(true);
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/client-list');
    }
  };

  return (
    <div>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Clients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Client</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Client Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              placeholder=""
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              placeholder=""
                              minLength={10}
                              maxLength={10}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>Email <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Registration{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              selected={selectedDateOfBirth}
                              onChange={handleDateOfBirthChange}
                              format="DD-MM-YYYY"
                              wrapperClassName="date-picker"
                              style={{
                                borderColor: selectedDateOfBirth ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Hospital Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="hospital_name"
                              className="form-control"
                              type="text"
                              value={formData.hospital_name}
                              onChange={handleChange}
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <FormSelect
                            label="Department"
                            name="departments"
                            value={selectedDepartment}
                            onChange={(selected) => handleDepartmentChange(
                              selected,
                              formData,
                              setFormData,
                              'departments'
                            )}
                            options={departmentOptions}
                            isMulti
                            isCreatable
                            required
                          />
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              License Number <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="license_number"
                              value={formData.license_number}
                              onChange={handleChange}
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <FormSelect
                            label="Speciality"
                            name="speciality"
                            value={selectedSpeciality}
                            onChange={(selected) => handleSpecialityChange(
                              selected,
                              formData,
                              setFormData,
                              'speciality'
                            )}
                            options={specialityOptions}
                            required
                          />
                        </div>

                        <div className="row g-3" style={{marginTop: '-30px'}}>
                          <div className="col-12 col-md-4">
                            <ImagePreviewInput
                              label="Hospital Picture"
                              name="picture"
                              onChange={handleFileChange}
                              hint="Upload image size less than 1MB"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <ImagePreviewInput
                              label="Hospital Logo"
                              name="logo"
                              onChange={handleFileChange}
                              hint="Upload image size less than 1MB"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <ImagePreviewInput
                              label="Authorized Signature"
                              name="signature"
                              onChange={handleFileChange}
                              hint="Upload image size less than 1MB"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Postal Code</label>
                            <input
                              name="pin_code"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.pin_code}
                              minLength={6}
                              maxLength={6}
                              onChange={(e) => handlePincodeChange(e.target.value, setFormData)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>City</label>
                            <Select
                              options={cityOptions}
                              value={cityOptions.find(option => option.value === formData.city)}
                              onChange={(selectedOption) => handleCityChange(selectedOption, setFormData)}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Country</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={countryOptions}
                              value={countryOptions.find(option => option.value === formData.country)}
                              onChange={(selectedOption) => handleCountryChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>State/Province</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={stateOptions}
                              value={stateOptions.find(option => option.value === formData.state)}
                              onChange={(selectedOption) => handleStateChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Active"
                                  checked={formData.status === 'Active'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="InActive"
                                  checked={formData.status === 'InActive'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* TODO -  Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default AddClient;
