import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Login from "./components/pages/login";
import Register from "./components/pages/login/Register";
import LockScreen from "./components/pages/login/LockScreen";
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";
import BlankPage from "./components/pages/login/BlankPage";
import ForgotPassword from "./components/pages/login/ForgotPassword";
import Signup from "./components/pages/login/Signup";
import VerifyOtp from "./VerifyOtp";
import VerificationPopup from "./utils/VerficationPopup"
import VerifyEmailSuccess from "./VerifyEmail";


const PublicRouter = ({ user, authenticated }) => {

    useEffect(() => {
        if (authenticated) {
            console.log(user);
        }
    }, [authenticated, user]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={"/login"} />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/lockscreen" element={<LockScreen />} />
                    <Route path="/verification-popup" element={<VerificationPopup />} />
                    <Route path="/verify-otp" element={<VerifyOtp />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="/server-error" element={<ServerError />} />
                    <Route path="/blankpage" element={<BlankPage />} />
                    <Route path="*" element={<Error />} />
                    <Route path="/verify_email" element={<VerifyEmailSuccess />} />
                </Routes>
            </BrowserRouter>
            <div className="sidebar-overlay"></div>
        </>
    );
};

export default PublicRouter;