import patientApi from '../services/ClientServices/PatientRoutes';

export const saveCustomVitalRanges = async (selectedPatient, vitalRanges, setCustomRanges, setShowModal) => {
    if (!selectedPatient?.patient_id) {
        console.error('Invalid patient data');
        return;
    }

    const formatVitalRange = (value) => {
        const num = Number(value);
        return isNaN(num) ? null : num;
    };

    try {
        const requestBody = {
            vitalsRange: {
                dbp: {
                    max: formatVitalRange(vitalRanges.dbp?.max),
                    min: formatVitalRange(vitalRanges.dbp?.min)
                },
                hr: {
                    max: formatVitalRange(vitalRanges.hr?.max),
                    min: formatVitalRange(vitalRanges.hr?.min)
                },
                hrv: {
                    max: formatVitalRange(vitalRanges.hrv?.max) || 100,
                    min: formatVitalRange(vitalRanges.hrv?.min) || 20
                },
                rr: {
                    max: formatVitalRange(vitalRanges.rr?.max),
                    min: formatVitalRange(vitalRanges.rr?.min)
                },
                sbp: {
                    max: formatVitalRange(vitalRanges.sbp?.max),
                    min: formatVitalRange(vitalRanges.sbp?.min)
                },
                skt: {
                    max: formatVitalRange(vitalRanges.skt?.max),
                    min: formatVitalRange(vitalRanges.skt?.min)
                },
                sp02: {
                    max: formatVitalRange(vitalRanges.spo2?.max),
                    min: formatVitalRange(vitalRanges.spo2?.min)
                }
            }
        };

        await patientApi.upsertCustomVitals(selectedPatient.patient_id, requestBody);

        setCustomRanges(prev => ({
            ...prev,
            [selectedPatient.patient_id]: vitalRanges,
        }));

        alert('Custom vital ranges updated successfully');
        onSuccess();
    } catch (error) {
        console.error('Error updating custom vital ranges:', error);
        alert('Failed to update custom vital ranges. Please try again.');
    } finally {
        setShowModal(false);
    }
};