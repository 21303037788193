import React from 'react';
import FormInput from './FormInput';

const FormSection = ({ fields, onChange }) => (
  <>
    {fields.map(field => (
      <FormInput
        key={field.name}
        label={field.label}
        name={field.name}
        type={field.type || 'text'}
        value={field.value}
        onChange={onChange}
      />
    ))}
  </>
);

export default FormSection;