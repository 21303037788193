/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { favicon, imagesend } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import patientApi from "../../services/ClientServices/PatientRoutes";
import Select from "react-select";
import { Modal } from "bootstrap";
import { usePatient } from "../../context/PatientContext";
import DeleteIcon from "../../assets/img/delete.png";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';

const EditPatients = ({ clinic_id }) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const { patientId } = usePatient();  // Call usePatient once
  const navigate = useNavigate();
  const location = useLocation();
  const isViewMode = location.state?.type === 'view';
  const [activeTab, setActiveTab] = useState('general');

  const blankProfileImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Cpath d='M100 60a20 20 0 1 1 0 40 20 20 0 0 1 0-40zm0 55c25 0 45 20 45 45H55c0-25 20-45 45-45' fill='%23cccccc'/%3E%3C/svg%3E";


  const cityOptions = [
    { value: "Select City", label: "Select City" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Rajhamundry", label: "Rajhamundry" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Pune", label: "Pune" },
  ];

  const countryOptions = [
    { value: "Select Country", label: "Select Country" },
    { value: "India", label: "India" },
  ];

  const stateOptions = [
    { value: "Select State", label: "Select State" },
    { value: "Telangana", label: "Telangana" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
  ];

  const relationOptions = [
    { value: "self", label: "Self" },
    { value: "mother", label: "Mother" },
    { value: "father", label: "Father" },
    { value: "spouse", label: "Spouse" },
    { value: "son", label: "Son" },
    { value: "daughter", label: "Daughter" },
    { value: "other", label: "Other" },
  ];

  const handleRelationChange = (selectedOption) => {
    setSelectedRelation(selectedOption);
    setFormData({
      ...formData,
      relation: selectedOption ? selectedOption.value : '',
    });
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    setFormData({
      ...formData,
      city: selectedOption ? selectedOption.value : '',
    });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData({
      ...formData,
      country: selectedOption ? selectedOption.value : '',
    });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setFormData({
      ...formData,
      state: selectedOption ? selectedOption.value : '',
    });
  };

  const [formData, setFormData] = useState({
    address: '',
    pin_code: '',
    city: '',
    state: '',
    country: '',
    doctor_id: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    age: '',
    height: '',
    weight: '',
    email: '',
    bmi: '',
    bmi_status: '',
    blood_group: '',
    mobile: '',
    secondary_mobile: '',
    relation: '',
    symptoms: '',
    patient_uid: '',
    status: '',
    doctor_name: '',
  });

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!patientId || isNaN(patientId)) {
        console.error('Invalid patientId:', patientId);
        return;
      }
      try {
        const response = await patientApi.readPatient(patientId);

        if (response && response.patients && response.patients.length > 0) {
          const patientData = response.patients[0];
          setFormData(patientData);
          setSelectedCity({ value: patientData.city || '', label: patientData.city || '' });
          setSelectedCountry({ value: patientData.country || '', label: patientData.country || '' });
          setSelectedState({ value: patientData.state || '', label: patientData.state || '' });
          setSelectedRelation({ value: patientData.relation || '', label: patientData.relation || '' });

        } else {
          console.error('No patient data found');
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };
    fetchPatientData();
  }, [patientId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!patientId || isNaN(patientId)) {
      console.error('Invalid patientId:', patientId);
      setPopupMessage('Invalid patient ID.');
      new Modal(document.getElementById('popupMessageModal')).show();
      return;
    }

    const patientRequest = {};
    const intFields = ['pin_code', 'doctor_id', 'age'];
    const numberFields = ['height', 'weight'];

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== '') {
        if (key === 'symptoms' && typeof formData[key] === 'string') {
          // Ensure that symptoms is a string before splitting
          patientRequest[key] = formData[key].split(',').map(symptom => symptom.trim());
        } else if (intFields.includes(key)) {
          patientRequest[key] = parseInt(formData[key], 10);
        } else if (numberFields.includes(key)) {
          patientRequest[key] = parseFloat(formData[key]);
        } else {
          patientRequest[key] = formData[key];
        }
      }
    });

    try {
      const response = await patientApi.updatePatient(patientId, patientRequest);
      setIsSuccessful(true);
      setPopupMessage(response.message || 'Patient updated successfully.');
    } catch (error) {
      setIsSuccessful(false);
      setPopupMessage('Failed to update patient. Please try again.');
    }
    new Modal(document.getElementById('popupMessageModal')).show();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/patient-list');
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Edit Patient</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {isViewMode ? (
            <div className="row mt-4">
              <div className="col-xl-3 col-md-4">
                <div className="card shadow-sm" style={{ padding: '0' }}>
                  <div className="p-4">
                    <div className="text-center mb-3">
                      <div className="avatar-container" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                        <img
                          src={blankProfileImage}
                          alt="Client's Profile"
                          className="rounded-circle w-100 h-100"
                          style={{ objectFit: 'cover' }}
                          onError={(e) => {
                            e.target.src = blankProfileImage;
                          }}
                        />
                      </div>
                    </div>
                    <div className="profile-details">
                      <h5 className="text-center">
                        Patient: <span> {`${formData.first_name} ${formData.last_name}`} </span>
                      </h5>
                      <p className="text-center mb-0" style={{ fontSize: '13px', color: '#515365' }}>
                        <span>  Dr. {formData.doctor_name} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-9 col-md-8">
                {activeTab === 'general' && (
                  <div className="card invoices-settings-card">
                    <div className="card-header px-3">
                      <h5 className="card-title" style={{ color: '#2e37a4' }}>General Details</h5>
                    </div>
                    <div className="card-body">
                      {/* Form */}
                      <form action="#" className="invoices-settings-form">
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Patient Name
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={`${formData.first_name} ${formData.last_name}`} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Date of Birth
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.date_of_birth} className="form-control" />
                          </div>
                        </div>

                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.email} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="phone"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Mobile
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              value={formData.mobile}
                              className="form-control"
                              defaultValue="+91"
                            />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="phone"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Secondary Mobile
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              value={formData.secondary_mobile}
                              className="form-control"
                              defaultValue="+91"
                            />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Gender
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.gender} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Blood Group
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.blood_group} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Relation
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.relation} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Patient UID
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.patient_uid} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Doctor Name
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.doctor_name} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Height
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.height} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Weight
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.weight} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Symptoms
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.symptoms} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.address} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="zipcode"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Postal Code
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.pin_code} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            City
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.city} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Country
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.country} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            State
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.state} className="form-control" />
                          </div>
                        </div>
                      </form>
                      {/* /Form */}
                    </div>
                  </div>

                )}
                {activeTab === 'social' && (
                  <div className="card invoices-settings-card">
                    <div className="card-header px-3">
                      <h5 className="card-title">Social Media Settings</h5>
                    </div>
                    <div className="card-body">
                      <form action="#" className="invoices-settings-form">
                        {/* Add your social media settings form fields here */}
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Facebook ID
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.facebook_id} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Twitter ID
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.twitter_id} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            Instagram ID
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.instagram_id} className="form-control" />
                          </div>
                        </div>
                        <div className="row align-items-center form-group">
                          <label
                            htmlFor="text"
                            className="col-sm-3 col-form-label input-label"
                          >
                            LinkedIn ID
                          </label>
                          <div className="col-sm-9">
                            <input type="text" value={formData.linkedin_id} className="form-control" />
                          </div>
                        </div>
                        {/* Add more fields as needed */}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patients Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Secondary  Mobile
                            </label>
                            <input
                              name="secondary_mobile"
                              value={formData.secondary_mobile}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              minLength={10}
                              maxLength={10}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  className="form-check-input"
                                  checked={formData.gender === 'Male'}
                                  onChange={handleChange}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  className="form-check-input"
                                  checked={formData.gender === 'Female'}
                                  onChange={handleChange}
                                />
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Other"
                                  className="form-check-input"
                                  checked={formData.gender === 'Other'}
                                  onChange={handleChange}
                                />
                                Others
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              value={formData.date_of_birth}
                              onChange={handleChange}
                              dateFormat="yyyy-MM-dd"
                              wrapperClassName="date-picker"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Age
                            </label>
                            <input
                              className="form-control"
                              value={formData.age}
                              onChange={handleChange}
                              disabled
                              name="age"
                            />
                          </div>
                        </div>


                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Relation <span className="login-danger">*</span>
                            </label>
                            <Select
                              value={selectedRelation}
                              onChange={handleRelationChange}
                              options={relationOptions}
                              menuPortalTarget={document.body}
                              id="relation-select"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Blood Group
                            </label>
                            <input
                              name="blood_group"
                              value={formData.blood_group}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Doctor Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="doctor_name"
                              value={formData.doctor_name}
                              className="form-control"
                              type="text"
                              placeholder=""
                              readOnly
                            />
                            <input
                              name="doctor_id"
                              value={formData.doctor_id}
                              type="hidden"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Email
                            </label>
                            <input
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Height
                            </label>
                            <input
                              name="height"
                              value={formData.height}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Weight
                            </label>
                            <input
                              name="weight"
                              value={formData.weight}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Symptoms
                            </label>
                            <input
                              name="symptoms"
                              value={formData.symptoms}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder="Enter symptoms separated by commas"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Patient UID
                            </label>
                            <input
                              name="patient_uid"
                              value={formData.patient_uid}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Location
                            </label>
                            <input
                              name="location"
                              value={formData.location}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input

                                  type="radio"
                                  name="status"
                                  value="Active"
                                  className="form-check-input"
                                  checked={formData.status === 'Active'}
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Inactive"
                                  className="form-check-input"
                                  checked={formData.status === 'Inactive'}
                                  onChange={handleChange}
                                />
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update
                            </button>
                            <button
                              type="reset"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* Popup box */}
              <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      {isSuccessful ? (
                        <img src={SuccessIcon} width={32} alt="Success" />
                      ) : (
                        <img src={DeleteIcon} width={32} alt="Error" />
                      )}
                      <h3>{popupMessage}</h3>
                      <div className="m-t-20">
                        {isSuccessful ? (
                          <button
                            type="button"
                            className="btn btn-outline-success"
                            data-bs-dismiss="modal"
                            onClick={handleClose}
                            style={{ color: '#009F6F' }}

                            onMouseOver={(e) => e.target.style.color = 'white'}
                            onMouseOut={(e) => e.target.style.color = '#009F6F'}
                          >
                            Close
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            data-bs-dismiss="modal"
                            onClick={handleClose}
                            style={{ color: '#dc3545' }}

                            onMouseOver={(e) => e.target.style.color = 'white'}
                            onMouseOut={(e) => e.target.style.color = '#CA2127'}
                          >
                            Close
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* /Popup box */}
      </div>
    </div>
  );
};

export default EditPatients;
