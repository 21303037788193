// src/services/apiservice.js
import axios from 'axios';
import { store } from '../utils/redux/store'; // Import the Redux store
import { config } from '../config/config';

const apiClient = axios.create({
  baseURL: config.baseUrl + '/api/v2', // Use the dynamic base URL from config
  timeout: 10000, // Set timeout if needed
});
// Request interceptor to add Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token; // Get the token from the Redux store
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor (optional) for handling responses globally
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

export default apiClient;



