import React, { useEffect, useState } from "react";
import Select from "react-select";
import CountUp from "react-countup";
import { doctor_dashboard_01, doctor_dashboard_02, doctor_dashboard_03, doctor_dashboard_04, morning_img_02 } from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import PatientByDepartment from "./PatientsByDepartment"
import PatientsByGender from "./PatientByGender";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import dashboardApi from "../../../services/dahsboard/dashboard";


const Doctor_Dashboard = ({ user, authenticated, token, clinic_id, client_id }) => {
    const [dashboardData, setDashboardData] = useState({});
    const [showPage, setShowPage] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const fetchData = async () => {
        if (startDate && endDate) {
            const body = {
                duration: 300,
                start_date: startDate ? startDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
                end_date: endDate ? endDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
            };

            try {
                const resp = await dashboardApi.getAdminDashboardData(body);
                if (resp) {
                    setDashboardData(resp.doctor_dashboard);
                    setShowPage(true);
                }
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchData();
        }
    }, [startDate, endDate]); 

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-8">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Doctor Dashboard</li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mr-2" style={{ marginRight: '10px' }}>
                                        <div className="cal-icon">
                                            <DatePicker
                                                className="form-control datetimepicker"
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                                dateFormat="dd-MM-yyyy"
                                                wrapperClassName="date-picker"
                                                style={{
                                                    borderColor: startDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                                                }}
                                                placeholder="Start Date"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cal-icon">
                                            <DatePicker
                                                className="form-control datetimepicker"
                                                selected={endDate}
                                                onChange={handleEndDateChange}
                                                dateFormat="dd-MM-yyyy"
                                                wrapperClassName="date-picker"
                                                style={{
                                                    borderColor: endDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                                                }}
                                                placeholder="End Date"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="good-morning-blk">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="morning-user">
                                    <h2>
                                        Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam" : user?.name}!</span>
                                    </h2>
                                    <p>Have a nice day at work</p>
                                </div>
                            </div>
                            <div className="col-md-6 position-blk">
                                <div className="morning-img">
                                    <img src={morning_img_02} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="doctor-list-blk">
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="doctor-widget border-right-bg">
                                    <div className="doctor-box-icon flex-shrink-0">
                                        <img src={doctor_dashboard_01} alt="" />
                                    </div>
                                    <div className="doctor-content dash-count flex-grow-1">
                                        <h4>
                                            <CountUp delay={0.4} end={dashboardData.total_rpm_patients_doctor} duration={0.6} />
                                            <span className="counter-up"></span>
                                        </h4>
                                        <h5>RPM Patients</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="doctor-widget border-right-bg">
                                    <div className="doctor-box-icon flex-shrink-0">
                                        <img src={doctor_dashboard_02} alt="" />
                                    </div>
                                    <div className="doctor-content dash-count flex-grow-1">
                                        <h4>
                                            <CountUp delay={0.4} end={dashboardData.total_active_patients_doctor} duration={0.6} />
                                            <span className="counter-up"></span>
                                        </h4>
                                        <h5>Active Patients</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="doctor-widget border-right-bg">
                                    <div className="doctor-box-icon flex-shrink-0">
                                        <img src={doctor_dashboard_03} alt="" />
                                    </div>
                                    <div className="doctor-content dash-count flex-grow-1">
                                        <h4>
                                            <CountUp delay={0.4} end={dashboardData.total_inactive_patients_doctor} duration={0.6} />
                                            <span className="counter-up"></span>
                                        </h4>
                                        <h5>Inactive Patients</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="doctor-widget">
                                    <div className="doctor-box-icon flex-shrink-0">
                                        <img src={doctor_dashboard_04} alt="" />
                                    </div>
                                    <div className="doctor-content dash-count flex-grow-1">
                                        <h4>
                                            <CountUp delay={0.4} end={dashboardData.total_patients_doctor} duration={0.6} />
                                            <span className="counter-up"></span>
                                        </h4>
                                        <h5>Total Patients</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="chart-title patient-visit">
                                        <h4>Patient Visit by Gender</h4>
                                        <div>
                                            <ul className="nav chat-user-total">
                                                <li>
                                                    <i
                                                        className="fa fa-circle current-users"
                                                        aria-hidden="true"
                                                    />
                                                      Male {dashboardData.total_male_patients_doctor} %
                                                </li>
                                                <li>
                                                    <i className="fa fa-circle old-users" aria-hidden="true" />{" "}
                                                    Female {dashboardData.total_female_patients_doctor} %
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div id="patient-chart" />
                                    <PatientByDepartment
                                        chartData={dashboardData.patients_by_department} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    client_id: state.auth.client_id,
    clinic_id: state.auth.clinic_id
});
export default connect(mapStateToProps)(Doctor_Dashboard);