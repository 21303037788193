/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination'
import { imagesend, plusicon, refreshicon, searchnormal, } from '../imagepath';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import clinicApi from '../../services/ClientServices/ClinicRoutes';
import StatusButton from '../../utils/statusbutton';
import { useClinic } from '../../context/ClinicContext';
import { useNavigate, useLocation} from 'react-router-dom';
import { connect } from "react-redux";

const ClinicsList = ({ client_id }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { setClinicId } = useClinic();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchClinics = async () => {
    try {
      const data = await clinicApi.getClinics(client_id);
      setDataSource(data.clinics || []);
      setFilteredData(data.clinics || []);
      setError(null);
    } catch (error) {
      setError(error);
      setDataSource([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (client_id) {
      fetchClinics();
      setError(null);
    }
    else {
      setError("No client is selected, please select a client to proceed further.");
    }
  }, [client_id]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    const filtered = dataSource.filter((clinic) => {
      const searchValue = value.toLowerCase();
      return (
        clinic.name.toLowerCase().includes(searchValue) ||
        clinic.client_name.toLowerCase().includes(searchValue) ||
        clinic.mobile.includes(searchValue) ||
        clinic.email.toLowerCase().includes(searchValue) ||
        clinic.status.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filtered);

  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (id) => {
    setClinicId(id);
    navigate('/edit-clinic');
  };

  const handleViewClick = (id) => {
    setClinicId(id);
    navigate('/edit-clinic', { state: { type: 'view' } });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Clinic Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Hospital Name",
      dataIndex: "client_name",
      sorter: (a, b) => a.client_name.length - b.client_name.length
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render: (text, record) => (
        <Link to="#">{record.mobile}</Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => (
        <StatusButton
          status={status}
          clinicId={record.id}
          type="clinic"
          mobileNumber={record.mobile}
          isMobileVerified={record.is_mobile_verified}
          isEmailVerified={record.is_email_verified}
          userEmail={record.email}
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>
        </div>
      ),
    }
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Clinics </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Clinic List</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
          </div>
          {/* /Page Header */}
          <div className="d-inline-block" style={{ textAlign: 'center' }}>
            <div
              className="alert alert-danger"
              role="alert"
              style={{ display: error ? 'block' : 'none', marginBottom: '10px' }}
            >
              {error}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Clinic List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  value={searchQuery}
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#"
                                  />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/add-clinic"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                                onClick={fetchClinics}
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: filteredData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={filteredData}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Notification-box -- TODO */}
      </div>
      <div id="delete_patient" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Close
                </Link>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  client_id: state.auth.client_id
});


export default connect(mapStateToProps)(ClinicsList);
