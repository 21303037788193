import React, { useState, useEffect } from 'react';

const ImagePreviewInput = ({ 
  label, 
  name, 
  onChange, 
  hint,
  initialPreview,
  defaultImage,
  onImageUpdate,
  variant = 'simple' // 'simple' for first style, 'detailed' for second style
}) => {
  const [preview, setPreview] = useState(initialPreview || defaultImage);
  const [fileName, setFileName] = useState(initialPreview ? 'Existing image' : 'No file chosen');

  useEffect(() => {
    if (initialPreview) {
      setPreview(initialPreview);
      setFileName('Existing image');
    } else {
      setFileName('No file chosen');
    }
  }, [initialPreview]);

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;
          const maxSize = 800;

          if (width > height && width > maxSize) {
            height = Math.round((height * maxSize) / width);
            width = maxSize;
          } else if (height > maxSize) {
            width = Math.round((width * maxSize) / height);
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          
          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          }, 'image/jpeg', 0.7);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setFileName(file.name);
        
        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);

        // Compress image
        const compressedFile = await compressImage(file);
        
        // Create a new event with the compressed file
        const compressedEvent = {
          target: {
            name: name,
            files: [compressedFile],
            value: compressedFile,
            type: 'file'
          }
        };

        onChange(compressedEvent);
        if (onImageUpdate) {
          onImageUpdate(name, true);
        }

        console.log('Original size:', file.size / 1024 / 1024, 'MB');
        console.log('Compressed size:', compressedFile.size / 1024 / 1024, 'MB');
      } catch (error) {
        console.error('Error compressing image:', error);
        onChange(e);
      }
    }
  };

  const clearImage = () => {
    setPreview(null);
    setFileName('No file chosen');
    if (onImageUpdate) {
      onImageUpdate(name, true);
    }
  };

  const renderSimpleVariant = () => (
    <div className="form-group theme-set w-100" style={{height: '75px', marginBottom: '40px'}}>
      <label
        className="local-top"
        style={{
          fontSize: '12px',
          color: '#333448',
          fontWeight: 500,
          background: '#fff',
          marginTop: 0,
          marginBottom: 0,
          padding: '0px 5px',
        }}
      >
        {label}
      </label>
      <div className="d-flex align-items-center gap-2">
        <div 
          className="rounded-circle overflow-hidden bg-light"
          style={{
            width: '40px',
            height: '40px',
            border: '1px solid #e6e6e6',
            flexShrink: 0,
            position: 'relative',
            marginTop:'-20px'
          }}
        >
          {preview ? (
            <img
              src={preview}
              alt={`${name} preview`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <div 
              className="w-100 h-100 d-flex align-items-center justify-content-center text-muted"
              style={{ fontSize: '8px', textAlign: 'center', padding: '5px' }}
            >
              No image
            </div>
          )}
        </div>
        <div className="flex-grow-1">
          <input
            className="form-control"
            type="file"
            name={name}
            accept="image/*"
            onChange={handleFileChange}
          />
          {hint && (
            <span className="form-text text-muted" style={{
              fontSize: '12px',
              marginTop: '4px',
              display: 'block',
            }}>
              {hint}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const renderDetailedVariant = () => (
    <div className="form-group">
      <label
        className="local-top"
        style={{
          fontSize: '12px',
          color: '#333448',
          fontWeight: 500,
          background: '#fff',
          marginBottom: 0,
          padding: '0px 5px',
        }}
      >
        {label}
      </label>
      <div className="input-group">
        <input
          type="text"
          value={fileName}
          readOnly
          className="form-control bg-gray-50"
          onClick={() => document.getElementById(`file-${name}`).click()}
          style={{
            backgroundColor: '#F9FAFB',
            border: '1px solid #E4E7EC',
            borderRadius: '8px 0 0 8px',
            cursor: 'pointer'
          }}
        />
        <label
          htmlFor={`file-${name}`}
          className="input-group-text"
          style={{
            backgroundColor: '#F9FAFB',
            border: '1px solid #E4E7EC',
            borderLeft: 'none',
            cursor: 'pointer',
            fontSize: '12px'
          }}
        >
          Choose file
        </label>
        {fileName !== 'No file chosen' && (
          <button
            type="button"
            onClick={clearImage}
            className="btn btn-outline-secondary"
            style={{
              border: '2px solid #E4E7EC',
              borderRadius: '0px 8px 8px 0px',
            }}
          >
            ×
          </button>
        )}
        <input
          id={`file-${name}`}
          name={name}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>
      {preview && (
        <div className="mt-2">
          <img
            src={preview}
            alt={`Selected ${label}`}
            style={{
              width: '70px',
              height: '70px',
              objectFit: 'cover',
              borderRadius: '4px',
              fontSize: '10px',
              marginBottom: '20px'
            }}
          />
        </div>
      )}
    </div>
  );

  return variant === 'simple' ? renderSimpleVariant() : renderDetailedVariant();
};

export default ImagePreviewInput;