import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ClientsTable = ({ patientsData }) => {
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    // Update sortedData whenever patientsData changes
    useEffect(() => {
        setSortedData(patientsData);
    }, [patientsData]);

    // Sort function
    const sortData = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }

        const sortedArray = [...sortedData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });

        setSortedData(sortedArray);
        setSortConfig({ key, direction });
    };

    // Get sorting arrow based on direction
    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "↑" : "↓";
        }
        return null;
    };

    return (
        <div className="col-12 col-md-12 col-xl-5">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title d-inline-block">Patients in Each Client</h4>
                    <Link
                        to="/client-list"
                        className="patient-views float-end"
                        style={{ color: "#2e37a4", fontWeight: "600" }}
                    >
                        View Clients
                    </Link>
                </div>
                <div className="card-body p-0 table-dash">
                    <div
                        className="table-responsive"
                        style={{
                            maxHeight: "250px",
                            minHeight: "250px",
                            overflowY: "auto",
                        }}
                    >
                        <table className="table mb-0 border-0 custom-table">
                            {/* Table Header */}
                            <thead className="bg-light">
                                <tr>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(0)} // Sort by Client Name
                                    >
                                        Client Name{" "}
                                        <span>
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                    <th
                                        className="inovices-all"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortData(1)} // Sort by Reports Generated
                                    >
                                        Total Patients{" "}
                                        <span className="sort-icons">
                                            {getArrow(0)}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                                {sortedData.length > 0 ? (
                                    sortedData.map(([clientName, patientCount], index) => (
                                        <tr key={index}>
                                            <td className="table-image appoint-doctor">
                                                <p className="inovices-all">{clientName}</p>
                                            </td>
                                            <td className="appoint-time text-center">
                                                <div
                                                    className="inovices-amount"
                                                    style={{ color: "#2e37a4", fontWeight: "500" }}
                                                >
                                                    {patientCount}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2" className="text-center" > No data available </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientsTable;
