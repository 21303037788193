/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import clinicApi from "../../services/ClientServices/ClinicRoutes";
import { Modal } from "bootstrap";
import { useClinic } from '../../context/ClinicContext';
import { useLocation } from 'react-router-dom';
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";

const EditClinic = () => {
  const [popupMessage, setPopupMessage] = useState('');
  const { clinicId } = useClinic();
  const location = useLocation();
  const isViewMode = location.state?.type === 'view';
  const [activeTab, setActiveTab] = useState('general');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  const blankProfileImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Cpath d='M100 60a20 20 0 1 1 0 40 20 20 0 0 1 0-40zm0 55c25 0 45 20 45 45H55c0-25 20-45 45-45' fill='%23cccccc'/%3E%3C/svg%3E";


  function handleCityChange(selectedOption) {
    setSelectedCity(selectedOption);
    setClinicData({
      ...clinicData,
      city: selectedOption ? selectedOption.value : '',
    });
  }

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setClinicData({
      ...clinicData,
      country: selectedOption ? selectedOption.value : '',
    });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setClinicData({
      ...clinicData,
      state: selectedOption ? selectedOption.value : '',
    });
  };

  const cityOptions = [
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Rajhamundry", label: "Rajhamundry" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Pune", label: "Pune" },
  ];

  const countryOptions = [
    { value: "India", label: "India" },
  ];

  const stateOptions = [
    { value: "Telangana", label: "Telangana" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
  ];

  const [clinicData, setClinicData] = useState({
    name: '',
    mobile: '',
    email: '',
    license_number: '',
    address: '',
    city: '',
    country: '',
    state: '',
    pin_code: '',
    account_holder_name: '',
    account_number: '',
    ifsc_code: '',
    bank_branch: '',
    status: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch clinic data using the read API
    const fetchClinicData = async () => {
      try {
        const response = await clinicApi.readClinic(clinicId);
        const clinicDetails = response.clinics[0];
        setClinicData(clinicDetails);
        setLoading(false);
      } catch (error) {
        setError('Failed to load clinic data');
        setLoading(false);
      }
    };

    fetchClinicData();
  }, [clinicId]);

  const handleChange = (e) => {
    setClinicData({
      ...clinicData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await clinicApi.updateClinic(clinicId, clinicData);
      setIsSuccessful(true);
      setPopupMessage('Clinic updated successfully');
    } catch (error) {
      setIsSuccessful(false);
      setPopupMessage('Failed to update clinic');
    }
    new Modal(document.getElementById('popupMessageModal')).show();
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/clinic-list');
    }
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      {/* <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="edit-clinic" /> */}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Clinics </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Clinic</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {isViewMode ? (
              <div className="row mt-4">
                <div className="col-xl-3 col-md-4">
                  <div className="card shadow-sm" style={{ padding: '0' }}>
                    <div className="p-4">
                      <div className="text-center mb-3">
                        <div className="avatar-container" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                          <img
                            src={blankProfileImage}
                            alt="Client's Profile"
                            className="rounded-circle w-100 h-100"
                            style={{ objectFit: 'cover' }}
                            onError={(e) => {
                              e.target.src = blankProfileImage;
                            }}
                          />
                        </div>
                      </div>
                      <div className="profile-details">
                        <h5 className="text-center">
                          {clinicData.client_name}
                        </h5>
                        <p className="text-center mb-0" style={{ fontSize: '13px', color: '#515365' }}>
                          <span> {clinicData.name}  </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-md-8">
                  {activeTab === 'general' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title" style={{color: '#2e37a4'}}>General Details</h5>
                      </div>
                      <div className="card-body">
                        {/* Form */}
                        <form action="#" className="invoices-settings-form">
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Hospital Name
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.client_name} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Clinic Name
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.name} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="phone"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Mobile
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                value={clinicData.mobile}
                                defaultValue="+91"
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="email"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.email} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              License Number
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.license_number} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Address
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.address} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="zipcode"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Postal Code
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.pin_code} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              City
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.city} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Country
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.country} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              State
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={clinicData.state} className="form-control" />
                            </div>
                          </div>
                        </form>
                        {/* /Form */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Clinic Details</h4>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Clinic Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="name"
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={clinicData.name}
                                onChange={handleChange}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Mobile <span className="login-danger">*</span>
                              </label>
                              <input
                                name="mobile"
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={clinicData.mobile}
                                onChange={handleChange}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Email <span className="login-danger">*</span>
                              </label>
                              <input
                                name="email"
                                className="form-control"
                                type="email"
                                placeholder=""
                                value={clinicData.email}
                                onChange={handleChange}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                License Number <span className="login-danger">*</span>
                              </label>
                              <input
                                name="license_number"
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={clinicData.license_number}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Address
                              </label>
                              <textarea
                                name="address"
                                className="form-control"
                                rows={3}
                                cols={30}
                                value={clinicData.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Pincode
                              </label>
                              <input
                                name="pin_code"
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={clinicData.pin_code}
                                onChange={handleChange}
                                minLength={6}
                                maxLength={6}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                City
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={clinicData.city}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Country
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="country"
                                value={clinicData.country}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                State/Province
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                value={clinicData.state}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Status <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="Active"
                                    className="form-check-input"
                                    checked={clinicData.status === 'Active'}
                                    onChange={handleChange}
                                  />
                                  Active
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="InActive"
                                    className="form-check-input"
                                    checked={clinicData.status === 'InActive'}
                                    onChange={handleChange}
                                  />
                                  InActive
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button type="submit" className="btn btn-primary submit-form me-2" >
                                Update
                              </button>
                              <button type="reset" className="btn btn-primary cancel-form" >
                                Cancel
                              </button>
                            </div>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default EditClinic;
