import React from 'react';

const FormInput = ({ label, name, type = "text", value, onChange }) => (
  <div className="row align-items-center form-group">
    <label htmlFor={name} className="col-sm-3 col-form-label input-label">
      {label}
    </label>
    <div className="col-sm-9">
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control"
      />
    </div>
  </div>
);

export default FormInput;