// dashboardApi.js
import apiClient from '../apiservice';

const dashboardApi = {
  getAdminDashboardData: async (dashboardData) => {
    try {
      const response = await apiClient.post(`/dashboard/getdashboard/`, dashboardData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in getAdminDashboardData API:", error);
      throw error;
    }
  },
};

export default dashboardApi;
