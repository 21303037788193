import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import  { sendOtp, sendVerificationEmail } from "../services/userService"

const VerificationPopup = ({ emailVerified, mobileVerified, onClose, clientId, clinicId, mobileNumber, email }) => {
    const navigate = useNavigate();
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingMobile, setLoadingMobile] = useState(false);
    const [emailSent, setEmailSent] = useState(false); // New state for email sent confirmation

    const handleVerifyClick = async (type) => {
        if (type === 'mobile') {
            setLoadingMobile(true);
            const response = await sendOtp(mobileNumber);
            if (response) {
                navigate('/verify-otp', { state: { clientId, clinicId, mobileNumber, email, mobileVerified: false, emailVerified, isMobileVerification: true ,
                    returnPath: window.location.pathname 
                } });
            } else {
                console.error('Failed to send mobile OTP');
            }
            setLoadingMobile(false);
        } else if (type === 'email') {
            setLoadingEmail(true);
            const response = await sendVerificationEmail(email);
            if (response) {
                setEmailSent(true); // Set emailSent to true
            } else {
                console.error('Failed to send email OTP');
            }
            setLoadingEmail(false);
        }
    };

    return (
        <div
            id="popupMessageModal"
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            aria-labelledby="popupMessageModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    
                    {/* Modal header with cross icon */}
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            style={{ fontSize: '24px', border: 'none', background: 'none', color: '#000' }}
                        >
                            &times;
                        </button>
                    </div>

                    <div className="modal-body text-center">
                        <h3 style={{ marginTop: '20px' }}>Verification Status</h3>

                        {/* Display email sent confirmation */}
                        {emailSent ? (
                            <div className="alert alert-success" style={{ margin: '30px' }}>
                               We’ve just sent you a verification link!
                                <br/> Please check your inbox to confirm your email.
                                <br/> If it’s not there, don’t forget to check your spam folder.
                            </div>
                        ) : (
                            <>
                                <div className="row" style={{ margin: '35px 0' }}>
                                    <div className="col-12 col-sm-6">
                                        <p style={{ margin: '10px 0', fontSize: '16px' }}>
                                            Email Verified:
                                            <button
                                                className={`btn btn-sm ${emailVerified ? 'btn-success' : 'btn-danger'}`}
                                                style={{ marginLeft: '10px', padding: '5px 15px' }}
                                            >
                                                {emailVerified ? 'Yes' : 'No'}
                                            </button>
                                        </p>
                                    </div>

                                    <div className="col-12 col-sm-6">
                                        <p style={{ margin: '10px 0', fontSize: '16px' }}>
                                            Mobile Verified:
                                            <button
                                                className={`btn btn-sm ${mobileVerified ? 'btn-success' : 'btn-danger'}`}
                                                style={{ marginLeft: '10px', padding: '5px 15px' }}
                                            >
                                                {mobileVerified ? 'Yes' : 'No'}
                                            </button>
                                        </p>
                                    </div>
                                </div>
                                <div className="m-t-20">
                                    <div className="d-flex justify-content-center align-items-center mb-3">
                                        {!emailVerified && (
                                            <button
                                                type="button"
                                                className="btn btn-primary me-2"
                                                onClick={() => handleVerifyClick('email')}
                                                style={{ padding: '8px 35px' }}
                                                disabled={loadingEmail}
                                            >
                                                {loadingEmail ? 'Sending Email OTP...' : 'Verify Email'}
                                                {loadingEmail && <span className="spinner-border spinner-border-sm ms-2"></span>}
                                            </button>
                                        )}
                                        {!mobileVerified && (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleVerifyClick('mobile')}
                                                style={{ padding: '8px 35px' }}
                                                disabled={loadingMobile}
                                            >
                                                {loadingMobile ? 'Sending Mobile OTP...' : 'Verify Mobile'}
                                                {loadingMobile && <span className="spinner-border spinner-border-sm ms-2"></span>}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationPopup;
