import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import {Link} from "react-router-dom";
import TestingClinicalHealthCard from './ClinicalHealthCard'; // Import the converted component
import "./rpm_dashboard.css";

const RpmDashboardTesting = () => {
    return (
        <>
            <TestingClinicalHealthCard/>
        </>
    );
};

export default RpmDashboardTesting;
