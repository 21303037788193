import { useState } from 'react';
// constants/formOptions.js
export const departmentOptions = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Orthopedic", label: "Orthopedic" },
    { value: "Radiology", label: "Radiology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Oncology", label: "Oncology" },
];

export const specialityOptions = [
    { value: "Select Speciality", label: "Select Speciality" },
    { value: "Multi Speciality", label: "Multi Speciality" },
    { value: "Large Hospital", label: "Large Hospital" },
    { value: "Corporate Hospital", label: "Corporate Hospital" },
    { value: "Nursing Home", label: "Nursing Home" },
    { value: "Clinic", label: "Clinic" },
];

// styles/selectStyles.js
export const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
        },
        borderRadius: '10px',
        fontSize: "14px",
        minHeight: "45px",
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
        transition: '250ms',
        width: '35px',
        height: '35px',
    }),
};

// components/FormSelect.jsx
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export const FormSelect = ({
    label,
    name,
    value,
    onChange,
    options,
    isMulti = false,
    isCreatable = false,
    required = false,
}) => {
    const SelectComponent = isCreatable ? CreatableSelect : Select;

    return (
        <div className="form-group local-forms">
            <label>
                {label} {required && <span className="login-danger">*</span>}
            </label>
            <SelectComponent
                name={name}
                menuPosition="fixed"
                value={value}
                onChange={onChange}
                options={options}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    ...selectStyles
                }}
                id={`search-${name}`}
                components={{ IndicatorSeparator: () => null }}
                isMulti={isMulti}
                isClearable
            />
        </div>
    );
};

export const useFormSelect = (initialValue = null) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);

    const handleChange = (selected, formData, setFormData, fieldName) => {
        setSelectedValue(selected);

        if (Array.isArray(selected)) {
            const values = selected.map(option =>
                option.__isNew__ ? option.value : option.value
            );
            setFormData({
                ...formData,
                [fieldName]: values,
            });
        } else {
            setFormData({
                ...formData,
                [fieldName]: selected ? selected.value : '',
            });
        }
    };

    return [selectedValue, setSelectedValue, handleChange];
};

// Usage in your form component:
const FormComponent = () => {
    const [formData, setFormData] = useState({
        departments: [],
        speciality: '',
        // ... other fields
    });

    const [selectedDepartment, setSelectedDepartment, handleDepartmentChange] =
        useFormSelect(null);
    const [selectedSpeciality, setSelectedSpeciality, handleSpecialityChange] =
        useFormSelect(null);

    return (
        <div className="row">
            <div className="col-12 col-md-6 col-xl-3">
                <FormSelect
                    label="Department"
                    name="departments"
                    value={selectedDepartment}
                    onChange={(selected) => handleDepartmentChange(
                        selected,
                        formData,
                        setFormData,
                        'departments'
                    )}
                    options={departmentOptions}
                    isMulti
                    isCreatable
                    required
                />
            </div>

            <div className="col-12 col-md-6 col-xl-3">
                <FormSelect
                    label="Speciality"
                    name="speciality"
                    value={selectedSpeciality}
                    onChange={(selected) => handleSpecialityChange(
                        selected,
                        formData,
                        setFormData,
                        'speciality'
                    )}
                    options={specialityOptions}
                    required
                />
            </div>
        </div>
    );
};