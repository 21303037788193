export const doctorFormFields = {
  personal: [
    { label: 'Full Name', name: 'full_name' },
    { label: 'Date of Birth', name: 'date_of_birth' },
    { label: 'Mobile', name: 'mobile', type: 'tel' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Blood Group', name: 'blood_group' },
    { label: 'Gender', name: 'gender' }
  ],
  professional: [
    { label: 'Degree', name: 'degree' },
    { label: 'Specialization', name: 'specialities' },
    { label: 'Department', name: 'departments' }
  ],
  address: [
    { label: 'Address', name: 'address' },
    { label: 'Postal Code', name: 'pin_code' },
    { label: 'City', name: 'city' },
    { label: 'State', name: 'state' },
    { label: 'Country', name: 'country' }
  ],
  social: [
    { label: 'Facebook ID', name: 'facebook_id' },
    { label: 'Twitter ID', name: 'twitter_id' },
    { label: 'LinkedIn ID', name: 'linkedin_id' },
    { label: 'Instagram ID', name: 'instagram_id' }
  ]
};

export const doctorInitialState = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  mobile: '',
  email: '',
  blood_group: '',
  gender: '',
  degree: '',
  specialities: [],
  departments: [],
  address: '',
  pin_code: '',
  city: '',
  state: '',
  country: '',
  facebook_id: '',
  twitter_id: '',
  linkedin_id: '',
  instagram_id: '',
  picture: null,
  signature: null
};


export const clientFormFields = {
  personal: [
    { label: 'Full Name', name: 'full_name' },
    { label: 'Mobile', name: 'mobile', type: 'tel' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Speciality', name: 'speciality' },
    { label: 'Date of Incorporation', name: 'incorp_date', type: 'date' },
    { label: 'Hospital Name', name: 'hospital_name' },
    { label: 'License Number', name: 'license_number' },
    { label: 'Departments', name: 'departments', type: 'multi-select' }
  ],
  address: [
    { label: 'Address', name: 'address' },
    { label: 'Postal Code', name: 'pin_code' },
    { label: 'City', name: 'city' },
    { label: 'State', name: 'state' },
    { label: 'Country', name: 'country' }
  ],
};

export const clientInitialState = {
  account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    pan_number: "",
    facebook_id: "",
    twitter_id: "",
    linkedin_id: "",
    instagram_id: "",
    address: "",
    pin_code: "",
    city: "",
    incorp_date: "",
    state: "",
    country: "",
    mobile: "",
    email: "",
    first_name: "",
    last_name: "",
    speciality: "",
    hospital_name: "",
    license_number: "",
    departments: [],
    status: "",
    logo: null,
    picture: '',
    signature: null,
};

