import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { doctor_dashboard_01, doctor_dashboard_02, doctor_dashboard_03, doctor_dashboard_04, morning_img_02, } from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import dashboardApi from "../../../services/dahsboard/dashboard";
import { DatePicker } from "antd";
import DoctorRefPatients from "./DoctorRef_Patients";
import DoctorRefReports from "./DoctorRef_Reports";

const Client_Dashboard = ({ user, authenticated, token, clinic_id, client_id }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [dashboardData, setDashboardData] = useState({});
  const [showPage, setShowPage] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const fetchData = async () => {
    if (startDate && endDate) {
      const body = {
        duration: 300,
        start_date: startDate ? startDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
        end_date: endDate ? endDate.toISOString().split('T')[0] : '', // Format date to yyyy-mm-dd
      };

      try {
        const resp = await dashboardApi.getAdminDashboardData(body);
        if (resp) {
          console.log("Dashboard Data:", resp); // Log the response
          setDashboardData(resp.client_dashboard);
          setShowPage(true);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    }
  };

  // Trigger fetchData when both start and end dates are selected
  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]); // Dependencies are startDate and endDate


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-8">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Client Dashboard</li>
                </ul>
              </div>
              <div className="col-sm-4">
                <div className="d-flex justify-content-end">
                  <div className="form-group mr-2" style={{ marginRight: '10px' }}>
                    <div className="cal-icon">
                      <DatePicker
                        className="form-control datetimepicker"
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd-MM-yyyy"
                        wrapperClassName="date-picker"
                        style={{
                          borderColor: startDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                        }}
                        placeholder="Start Date"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="cal-icon">
                      <DatePicker
                        className="form-control datetimepicker"
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd-MM-yyyy"
                        wrapperClassName="date-picker"
                        style={{
                          borderColor: endDate ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                        }}
                        placeholder="End Date"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>
                    Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam" : user?.name}!</span>
                  </h2>
                  <p>Have a nice day at work</p>
                </div>
              </div>
              <div className="col-md-6 position-blk">
                <div className="morning-img">
                  <img src={morning_img_02} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="doctor-list-blk">
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="doctor-widget border-right-bg">
                  <div className="doctor-box-icon flex-shrink-0">
                    <img src={doctor_dashboard_01} alt="" />
                  </div>
                  <div className="doctor-content dash-count flex-grow-1">
                    <h4>
                      <CountUp delay={0.4} end={dashboardData.total_clinics_client || 0} duration={0.6} />
                      <span className="status-green"></span>
                    </h4>
                    <h5>Total Clinics</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="doctor-widget border-right-bg">
                  <div className="doctor-box-icon flex-shrink-0">
                    <img src={doctor_dashboard_02} alt="" />
                  </div>
                  <div className="doctor-content dash-count flex-grow-1">
                    <h4>
                      <CountUp delay={0.4} end={dashboardData.total_doctors_client} duration={0.6} />
                      <span className="status-green"></span>
                    </h4>
                    <h5>Total Doctors</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="doctor-widget border-right-bg">
                  <div className="doctor-box-icon flex-shrink-0">
                    <img src={doctor_dashboard_03} alt="" />
                  </div>
                  <div className="doctor-content dash-count flex-grow-1">
                    <h4>
                      <CountUp delay={0.4} end={dashboardData.total_patients_client} duration={0.6} />
                      <span className="status-green"></span>
                    </h4>
                    <h5>Total Patients</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="doctor-widget">
                  <div className="doctor-box-icon flex-shrink-0">
                    <img src={doctor_dashboard_04} alt="" />
                  </div>
                  <div className="doctor-content dash-count flex-grow-1">
                    <h4>
                      <CountUp delay={0.4} end={dashboardData.total_nurses_client} duration={0.6} />
                      <span className="status-green"></span>
                    </h4>
                    <h5>Total Nurses</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <DoctorRefPatients dashboardData={dashboardData} />
            <DoctorRefReports dashboardData={dashboardData} />
          </div>

        </div>
      </div>
    </>
  )
};
const mapStateToProps = (state) => ({
  user: state.auth.userData,
  authenticated: state.auth.authenticated,
  token: state.auth.token,
  client_id: state.auth.client_id,
  clinic_id: state.auth.clinic_id
});
export default connect(mapStateToProps)(Client_Dashboard);
