/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import nurseApi from "../../services/ClientServices/NurseRoutes";
import { Modal } from "bootstrap";
import { useNurse } from '../../context/NurseContext';
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";
import CreatableSelect from 'react-select/creatable';
import CardLayout from "../form_components/CardLayout";
import FormSection from "../form_components/FormSection";
import { doctorFormFields } from "../form_components/FormFields";
import PageLayout from "../form_components/PageLayout";
import ImagePreviewInput from "../form_components/ImagePreviewInput";
import { departmentOptions, selectStyles, useFormSelect, FormSelect, bloodGroupOptions } from "../form_components/DrDropdownOptions";

const EditNurse = ({ clinic_id }) => {
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedSpeciality, setSelectedSpeciality] = useState([]);
  const [picturePreview, setPicturePreview] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [pictureFileName, setPictureFileName] = useState('Choose File');
  const [signatureFileName, setSignatureFileName] = useState('Choose File');
  const navigate = useNavigate();
  const { nurseId } = useNurse();
  const location = useLocation();
  const isViewMode = location.state?.type === 'view';
  const [activeTab, setActiveTab] = useState('general');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isPictureUpdated, setIsPictureUpdated] = useState(false);
  const [isSignatureUpdated, setIsSignatureUpdated] = useState(false);

  const blankProfileImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Cpath d='M100 60a20 20 0 1 1 0 40 20 20 0 0 1 0-40zm0 55c25 0 45 20 45 45H55c0-25 20-45 45-45' fill='%23cccccc'/%3E%3C/svg%3E";

  const [selectedDepartment, setSelectedDepartment, handleDepartmentChange] =
    useFormSelect(null);

  const specialityOptions = [
    { value: "Surgical Nurse", label: "Surgical Nurse" },
    { value: "Critical Care", label: "Critical Care" },
    { value: "Nurse Practitioner", label: "Nurse Practitioner" },
    { value: "Clinical Nurse Specialist", label: "Clinical Nurse Specialist" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSpecialityChange = (selectedOption) => {
    setSelectedSpeciality(selectedOption);
    setFormData({
      ...formData,
      specialities: selectedOption ? selectedOption.map(option => option.value) : [],
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        switch (name) {
          case 'picture':
            setPicturePreview(result);
            setFormData(prev => ({ ...prev, picture: file }));
            setIsPictureUpdated(true);
            break;
          case 'signature':
            setSignaturePreview(result);
            setFormData(prev => ({ ...prev, signature: file }));
            setIsSignatureUpdated(true);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    pan_number: "",
    facebook_id: "",
    twitter_id: "",
    linkedin_id: "",
    instagram_id: "",
    address: "",
    pin_code: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    email: "",
    date_of_birth: "",
    aadhar_number: "",
    degree: "",
    blood_group: "",
    specialities: [],
    license_number: "",
    departments: [],
    status: "",
    picture: null,
    signature: null,
    gender: "",
  });

  const formFields = {
    personal: [
      { label: 'Full Name', value: formData.first_name + ' ' + formData.last_name, name: 'full_name' },
      { label: 'Date of Birth', value: formData.date_of_birth, name: 'date_of_birth' },
      { label: 'Mobile', value: formData.mobile, name: 'mobile', type: 'tel' },
      { label: 'Email', value: formData.email, name: 'email', type: 'email' },
      { label: 'Blood Group', value: formData.blood_group, name: 'blood_group' },
      { label: 'Gender', value: formData.gender, name: 'gender' }
    ],
    professional: [
      { label: 'Degree', value: formData.degree, name: 'degree' },
      { label: 'Specialization', value: formData.specialities, name: 'specialities' },
      { label: 'Department', value: formData.departments, name: 'departments' }
    ],
    address: [
      { label: 'Address', value: formData.address, name: 'address' },
      { label: 'Postal Code', value: formData.pin_code, name: 'pin_code' },
      { label: 'City', value: formData.city, name: 'city' },
      { label: 'State', value: formData.state, name: 'state' },
      { label: 'Country', value: formData.country, name: 'country' }
    ],
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Map values to fields
  const getFieldsWithValues = (fields) => {
    return fields.map(field => ({
      ...field,
      value: field.name === 'full_name'
        ? `${formData.first_name} ${formData.last_name}`
        : formData[field.name]
    }));
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/nurse-list');
    }
  };

  useEffect(() => {
    const fetchNurseData = async () => {
      setLoading(true); // Start loading
      try {
        if (!Number.isInteger(nurseId)) {
          throw new Error('Invalid nurse_id, must be an integer');
        }

        const response = await nurseApi.readNurse(nurseId);
        if (response && response.nurses && response.nurses.length > 0) {
          const nurseDetails = response.nurses[0] || {};
          setFormData(nurseDetails);
          setSelectedDepartment(
            (nurseDetails.departments || []).map((dept) => ({
              value: dept,
              label: dept,
            }))
          );
          setSelectedSpeciality(
            (nurseDetails.specialities || []).map((spec) => ({
              value: spec,
              label: spec,
            }))
          );
          // Handle picture preview using picture_url_expiry
          if (response.picture_url_expiry) {
            setPicturePreview(response.picture_url_expiry);
          }

          // Handle signature preview using signature_url_expiry
          if (response.signature_url_expiry) {
            setSignaturePreview(response.signature_url_expiry);
          }
        } else {
          console.error('No nurse data found');
          setError('No nurse data found');
        }
      } catch (error) {
        console.error('Error fetching nurse data:', error);
        setError('Failed to load nurse data');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchNurseData();
  }, [clinic_id, nurseId]);

  const profilePicture = picturePreview || blankProfileImage;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    const nurseRequest = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (key === 'departments' || key === 'specialities') {
          nurseRequest[key] = formData[key];
        } else if (key === 'pin_code') {
          nurseRequest[key] = parseInt(formData[key], 10);
        } else if (key === 'date_of_birth') {
          nurseRequest[key] = formData[key];
        } else {
          nurseRequest[key] = formData[key];
        }
      }
    });

    data.append('nurse_request', JSON.stringify(nurseRequest));

    if (isPictureUpdated && formData.picture) data.append('picture', formData.picture);
    if (isSignatureUpdated && formData.signature) data.append('signature', formData.signature);

    try {
      const response = await nurseApi.updateNurse(nurseId, data);
      console.log('Nurse updated successfully:', response);
      setIsSuccessful(true);
      setPopupMessage(response.message);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error updating nurse:', error.response ? error.response.data : error.message);
      setPopupMessage('Failed to update nurse. Please try again.');
      setIsSuccessful(false);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [show, setShow] = useState(false);

  return (
    <div>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Nurses </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Nurse</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {isViewMode ? (
              <div className="row mt-4">
                <div className="col-xl-3 col-md-4">
                  <div className="card shadow-sm" style={{ padding: '0' }}>
                    <div className="p-4">
                      <div className="text-center mb-3">
                        <div className="avatar-container" style={{ width: '120px', height: '120px', margin: '0 auto' }}>
                          <img
                            src={picturePreview || blankProfileImage}
                            alt="Doctor's Profile"
                            className="rounded-circle w-100 h-100"
                            style={{ objectFit: 'cover' }}
                            onError={(e) => {
                              e.target.src = blankProfileImage;
                            }}
                          />
                        </div>
                      </div>
                      <div className="profile-details">
                        <h5 className="text-center">
                          {formData.first_name} {formData.last_name}
                        </h5>
                        <p className="text-center mb-0" style={{ fontSize: '13px', color: '#515365' }}>
                          <span> Clinic: {formData.clinic_name} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <PageLayout>
                  {activeTab === 'general' && (
                    <CardLayout title="General Details">
                      <FormSection
                        fields={getFieldsWithValues(doctorFormFields.personal)}
                        onChange={handleInputChange}
                      />
                      <FormSection
                        fields={getFieldsWithValues(doctorFormFields.professional)}
                        onChange={handleInputChange}
                      />
                      <FormSection
                        fields={getFieldsWithValues(doctorFormFields.address)}
                        onChange={handleInputChange}
                      />
                      <div className="row align-items-center form-group">
                        <div
                          className="col-sm-9 ml-auto" // Use ml-auto to push the content to the right
                          style={{
                            display: 'flex',
                            flexDirection: 'column', // Stack image and label vertically
                            alignItems: 'flex-end', // Align items to the right
                            marginLeft: '200px'
                          }}
                        >
                          <div
                            className="avatar-container"
                            style={{
                              width: '200px', // Adjust width as needed
                              height: '100px', // Adjust height as needed
                              border: '1px solid #ddd', // Optional border for better visibility
                              borderRadius: '4px', // Slight rounding for rectangle corners
                              overflow: 'hidden', // Ensures the image doesn't exceed the container
                            }}
                          >
                            <img
                              src={signaturePreview}
                              alt="Doctor's Signature"
                              className="w-100 h-100"
                              style={{
                                objectFit: 'contain', // Ensure the image fits properly
                              }}
                              onError={(e) => {
                                e.target.src = blankProfileImage; // Fallback image if error occurs
                              }}
                            />
                          </div>
                          <label
                            htmlFor="text"
                            className="mt-2 col-form-label input-label"
                            style={{
                              textAlign: 'right', // Align the text label to the right
                            }}
                          >
                            Nurse Signature
                          </label>
                        </div>
                      </div>
                    </CardLayout>
                  )}
                  {activeTab === 'social' && (
                    <CardLayout title="Social Media Settings">
                      <FormSection
                        fields={getFieldsWithValues(doctorFormFields.social)}
                        onChange={handleInputChange}
                      />
                    </CardLayout>
                  )}
                </PageLayout>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Nurse Details</h4>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                First Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Last Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms cal-icon">
                              <label>
                                Date Of Birth <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control datetimepicker"
                                value={formData.date_of_birth}
                                name="date_of_birth"
                                disabled
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          </div>


                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Mobile <span className="login-danger">*</span>
                              </label>
                              <input
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Email <span className="login-danger">*</span>
                              </label>
                              <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control"
                                type="email"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Gender <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Male"
                                    className="form-check-input"
                                    checked={formData.gender === 'Male'}
                                    onChange={handleChange}
                                  />
                                  Male
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Female"
                                    className="form-check-input"
                                    checked={formData.gender === 'Female'}
                                    onChange={handleChange}
                                  />
                                  Female
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Other"
                                    className="form-check-input"
                                    checked={formData.gender === 'Other'}
                                    onChange={handleChange}
                                  />
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <FormSelect
                              label="Department"
                              name="departments"
                              value={selectedDepartment}
                              onChange={(selected) => handleDepartmentChange(
                                selected,
                                formData,
                                setFormData,
                                'departments'
                              )}
                              options={departmentOptions}
                              isMulti
                              isCreatable
                              required
                            />
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Blood Group <span className="login-danger">*</span>
                              </label>
                              <input
                                name="blood_group"
                                className="form-control"
                                value={formData.blood_group}
                                onChange={handleChange}
                              />
                            </div>
                          </div>


                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Medical License Number
                              </label>
                              <input
                                name="license_number"
                                value={formData.license_number}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Specialization
                              </label>
                              <CreatableSelect
                                name="specialities"
                                menuPosition={'fixed'}
                                value={selectedSpeciality}
                                onChange={handleSpecialityChange}
                                options={specialityOptions}
                                menuPortalTarget={document.body}
                                styles={{ /* Custom Styles */ }}
                                id="search-commodity"
                                components={{ IndicatorSeparator: () => null }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                                isMulti
                                isClearable
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Degree
                              </label>
                              <input
                                name="degree"
                                value={formData.degree}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <ImagePreviewInput
                              label="Nurse Picture"
                              name="picture"
                              onChange={handleFileChange}
                              initialPreview={picturePreview}
                              defaultImage={blankProfileImage}
                              onImageUpdate={(name, isUpdated) => setIsPictureUpdated(isUpdated)}
                              variant="detailed"
                            />
                          </div>
                          
                          <div className="col-12 col-md-6 col-xl-6">
                            <ImagePreviewInput
                              label="Nurse Signature"
                              name="signature"
                              onChange={handleFileChange}
                              initialPreview={signaturePreview}
                              defaultImage={blankProfileImage}
                              onImageUpdate={(name, isUpdated) => setIsSignatureUpdated(isUpdated)}
                              variant="detailed"
                            />
                          </div>

                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Address
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Postal Code
                              </label>
                              <input
                                name="pin_code"
                                value={formData.pin_code}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                minLength={6}
                                maxLength={6}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                City
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Country
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                State/Province
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Status <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input

                                    type="radio"
                                    name="status"
                                    value="Active"
                                    className="form-check-input"
                                    checked={formData.status === 'Active'}
                                    onChange={handleChange}
                                  />
                                  Active
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="InActive"
                                    className="form-check-input"
                                    checked={formData.status === 'InActive'}
                                    onChange={handleChange}
                                  />
                                  InActive
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button
                                type="submit"
                                className="btn btn-primary submit-form me-2"
                              >
                                Update
                              </button>
                              <button
                                type="reset"
                                className="btn btn-primary cancel-form"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default EditNurse;
