import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { login02 } from '../../imagepath';
import { requestOtp, updatePassword, verifyOtp, verifyOtpForMobileReset } from "../../../services/userService";
import proRithmLogo from "../../../../src/assets/img/proRithm.png";
import { Eye, EyeOff } from 'react-feather';


const ForgotPassword = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState(null);
    const [otp, setOtp] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);
    const [showOtp, setShowOtp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [token, setToken] = useState(null);
    const [otpVisible, setOtpVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal visibility state

    const navigate = useNavigate();

    const getOtp = async () => {
        if (mobileNumber && mobileNumber.length === 10) {
            console.log(mobileNumber);
            setError(null);
            const resp = await requestOtp(mobileNumber);
            if (resp) {
                setShowOtp(true);
                // store.dispatch({type: 'NOTIFY', data: resp.message});
            } else {
                setError('Please enter valid mobile number');
            }
        } else {
            setError('Please enter valid mobile number');
        }
    }

    const submitOtp = async () => {
        if (otp && otp.length === 6) {
            setError(null);
            const resp = await verifyOtpForMobileReset({ otp, username: mobileNumber });
            if (resp) {
                setShowOtp(false);
                setShowPassword(true);
                // store.dispatch({type: 'NOTIFY', data: resp.message});
                setToken(resp.token)
            } else {
                setError('Please enter valid otp');
            }
        } else {
            setError('Please enter valid otp');
        }
    }

    const submitPassword = async () => {
        if (password && confirmPassword && password === confirmPassword) {
            setError(null);
            const resp = await updatePassword(token, { new_password: password, confirm_password: confirmPassword });
            if (resp) {
                setModalMessage('Password has been successfully changed. Login now with your new password.');
                setShowModal(true); // Show the modal upon success
            }
        } else if (password && confirmPassword && password !== confirmPassword) {
            setError('Passwords doesn\'t match');
        } else {
            setError('Please enter valid password');
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        if (modalMessage.includes('successfully')) {
            navigate('/login'); // Redirect to login page
        }
    };

    return (
        <div>
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        {/* Login logo */}
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">
                                <div className="log-img">
                                    <img
                                        className="img-fluid"
                                        src={login02}
                                        alt="Logo"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* /Login logo */}
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                <Link to="/">
                                                    <img src={proRithmLogo} width={165} height={45} alt="#" />
                                                </Link>
                                            </div>
                                            <h2 style={{ marginBottom: '5px' }}>Forgot Password?</h2>
                                            {/* Form */}
                                            {(!showOtp && !showPassword) && <>
                                                <p style={{ marginBottom: '40px', color: 'rgb(96 105 114)', fontSize: '12px' }}>Please enter the registered mobile number, we will send the OTP in this phone number</p>

                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Mobile Number <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" maxLength={10} minLength={10} value={mobileNumber}
                                                            onChange={(e) => setMobileNumber(e.target.value)} />
                                                        {error && <p className="login-danger"
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                textAlign: 'center'
                                                            }}>
                                                            {error}
                                                        </p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={getOtp}>
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {(showOtp && !showPassword) && <>
                                                <p style={{ marginBottom: '40px', color: 'rgb(96 105 114)', fontSize: '12px' }}>Please enter the OTP sent to your mobile number</p>
                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Enter OTP <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type={otpVisible ? 'text' : 'password'} value={otp} minLength={6} maxLength={6}
                                                            onChange={(e) => setOtp(e.target.value)} />
                                                        <span className="toggle-password" onClick={() => setOtpVisible(!otpVisible)}>
                                                            {otpVisible ? (
                                                                <EyeOff className="react-feather-custom" style={{ width: '16px', height: '16px' }} />
                                                            ) : (
                                                                <Eye className="react-feather-custom" style={{ width: '16px', height: '16px' }} />
                                                            )}
                                                        </span>
                                                        {error && <p className="login-danger"
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                textAlign: 'center'
                                                            }}>
                                                            {error}
                                                        </p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={submitOtp}>
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {(!showOtp && showPassword) && <>
                                                <form>
                                                <p style={{ marginBottom: '40px', color: 'rgb(96 105 114)', fontSize: '12px' }}>Please enter your new password to reset</p>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type={newPasswordVisible ? 'text' : 'password'}
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)} />
                                                        <span className="toggle-password" onClick={() => setNewPasswordVisible(!newPasswordVisible)} >
                                                            {newPasswordVisible ? (<EyeOff className="react-feather-custom" style={{ width: '16px', height: '16px' }} />) : (<Eye className="react-feather-custom" />)} </span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Confirm New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type={confirmPasswordVisible ? 'text' : 'password'}
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        />
                                                        <span className="toggle-password" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} >
                                                            {confirmPasswordVisible ? (<EyeOff className="react-feather-custom" style={{ width: '16px', height: '16px' }} />) : (<Eye className="react-feather-custom" />)}
                                                        </span>
                                                        {error && <p className="login-danger"
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                textAlign: 'center'
                                                            }}>
                                                            {error}
                                                        </p>}
                                                    </div>

                                                    {/* Internal CSS */}
                                                    <style jsx>{`
                                                    .react-feather-custom { width: 16px;  /* Adjust width */ height: 16px; /* Adjust height */ } `}
                                                    </style>

                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button"
                                                            onClick={submitPassword}>
                                                            Reset password
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {/* /Form */}
                                            <div className="next-sign">
                                                <p className="account-subtitle">
                                                    Go back to?
                                                    <Link to="/login"> Login</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Login Content */}
                    </div>
                </div>
            </div>


            {showModal && (
                <div
                    className="modal fade show"
                    style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleModalClose}
                                    style={{
                                        fontSize: '32px',
                                        border: 'none',
                                        background: 'none',
                                        color: '#000',
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <h3 style={{ marginTop: '20px', textAlign: 'center' }}>
                                Change Password
                            </h3>
                            <div
                                className="modal-body text-center alert alert-info"
                                style={{ margin: '30px' }}
                            >
                                {modalMessage}
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        handleModalClose();
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ForgotPassword;
