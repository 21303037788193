import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import Chart from 'chart.js/auto';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const ECGGraph = ({ patient }) => {
    const ecgChartRef = useRef(null);
    const [chart, setChart] = useState(null);
    const [chartData, setChartData] = useState({ labels: [], data: [] });
    const [dataQueue, setDataQueue] = useState([]); // Queue to store incoming data
    const [isAnimating, setIsAnimating] = useState(false);
    const [windowSize, setWindowSize] = useState(300);

    const options = {
        animation: {
            duration: 0, // Disable animation for smoother experience
        },
        scales: {
            x: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
            y: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const createChart = () => {
        const canvas = ecgChartRef.current;
        const desiredWidth = 350;
        const desiredHeight = 60;

        canvas.width = desiredWidth * 1.5;
        canvas.height = desiredHeight * 1.5;

        canvas.style.display = 'block';
        canvas.style.boxSizing = 'border-box';
        canvas.style.width = `${desiredWidth}px`;
        canvas.style.height = `${desiredHeight}px`;

        const ctx = canvas.getContext('2d');
        const ecgChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'ECG Chart',
                    data: [],
                    borderColor: 'rgba(36, 85, 174, 1)',
                    borderWidth: 1.5,
                    fill: false,
                    pointRadius: 0,
                }],
            },
            options: options,
        });

        setChart(ecgChart);
    };

    const updateChart = (newData) => {
        if (newData) {
            chartData.labels.push(newData.t);
            chartData.data.push(newData.e);

            if (chartData.data.length > windowSize) {
                chartData.labels.splice(0, 1);
                chartData.data.splice(0, 1);
            }

            setChartData({ ...chartData });

            if (chart) {
                chart.data.labels = chartData.labels;
                chart.data.datasets[0].data = chartData.data;
                chart.update();
            }
        }
    };

    const animateChart = () => {
        if (dataQueue.length > 0) {
            const nextDataPoint = dataQueue[0];
            setDataQueue(prev => prev.slice(1)); // Remove the processed data point
            updateChart(nextDataPoint);
        }
    };

    // Handle new ECG data
    useEffect(() => {
        if (patient?.ecg_clean && patient.ecg_clean.length > 0) {
            console.log(`Received ECG data length: ${patient.ecg_clean.length}`);
            // Add new data points to the queue
            setDataQueue(prev => [...prev, ...patient.ecg_clean]);
            
            // Start animation if not already running
            if (!isAnimating) {
                setIsAnimating(true);
            }
        }
    }, [patient?.ecg_clean]);

    // Animation loop
    useEffect(() => {
        let animationInterval;
        
        if (isAnimating) {
            animationInterval = setInterval(() => {
                animateChart();
            }, 10);
        }

        return () => {
            if (animationInterval) {
                clearInterval(animationInterval);
            }
        };
    }, [isAnimating, dataQueue]);

    // Initialize chart
    useEffect(() => {
        createChart();

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, []);

    return (
        <div
            style={{
                background: '#f9fafb',
                padding: '10px',
                borderRadius: '8px',
                textAlign: 'center',
                height: '80px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <canvas id={`ecg-chart${patient?.patient_id}`} ref={ecgChartRef} />
        </div>
    );
};

export default ECGGraph;